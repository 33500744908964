/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState,useEffect } from "react";

import {
    Card,
Table,
Space,
Tag,
Button,


} from "antd";
import type { ColumnsType } from 'antd/es/table';


import axios from "axios";
import { toast } from "react-toastify";

function ViewVendorPage() {
    const [data, setData] = useState(
      [
        {
          pk: 1,
          vendor_name: 'Loading..',
          vendor_email: "Loading...",
          vendor_company: 'Loading...',
 
        }
       
      ]
    )

    useEffect(() => {
      
      axios.post("/api/get-all-vendors").then((res)=>{
        console.log(res.data.data)
        setData(res.data.data)
      })
    
    }, [])
    
    interface DataType {
        pk: number;
        vendor_name: string;
        vendor_email: string;
        vendor_company: string;

      }
      
      const columns: ColumnsType<DataType> = [
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Vendor Email',
          dataIndex: 'vendor_email',
          key: 'vendor_email',
        },
        {
          title: 'Vendor Company',
          dataIndex: 'vendor_company',
          key: 'vendor_company',
        },
        // {
        //   title: 'Status',
        //   key: 'tags',
        //   dataIndex: 'tags',
        //   render: (_, { tags }) => (
        //     <>
        //       {tags.map((tag) => {
        //         let color = tag.length > 6 ? 'geekblue' : 'green';
        //         if (tag === 'pending') {
        //           color = 'yellow';
        //         }
        //         return (
        //           <Tag color={color} key={tag}>
        //             {tag.toUpperCase()}
        //           </Tag>
        //         );
        //       })}
        //     </>
        //   ),
        // },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (

              <Button  type="primary" icon={
                (<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
              </svg>)
              } size={"small"} style={{display:"flex",alignItems:"center",justifyContent:"center",fontSize:"12px"}} danger>Delete</Button>
     
          ),
        },
      ];
      

      
    return (
        <>
            <div className="layout-content">
                <Card title={(<h1 style={{color:"grey"}}>All Vendors</h1>)} style={{ width: "95%" }}>
                <Table dataSource={data} columns={columns} />
                </Card>
            </div>
        </>
    );
}

export default ViewVendorPage;
