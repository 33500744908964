import React, { useState, useRef, useMemo } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Upload,
  Spin,
  Tooltip
} from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import type { UploadProps } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

function UploadInvoice() {

  const { Option } = Select;
  const [invoice, setInvoice] = useState<any>()
  const [multiinvoice, setMultiInvoice] = useState<any>()
  const [invoiceType, setInvoiceType] = useState<any>()
  const [invoiceDate, setInvoiceDate] = useState<any>()
  const [pan, setPAN] = useState<any>()
  const [isLoading, setIsLoading] = useState(false);

  const props_invoice: UploadProps = {
    beforeUpload(file) {
      return false;
    },
    onChange(info) {
      setInvoice(info.file);
      return

    },
  };
  const props_multi_invoice: UploadProps = {
    beforeUpload(file) {
      return false;
    },
    onChange(info) {
      setMultiInvoice(info.file);
      return

    },
  };

  function handleChange(value: any) {
    setInvoiceType(value)
  }

  const dragger_props = {
    name: 'file',
    multiple: true,
    action: '/api/multi-upload', // Replace with your API endpoint
    onChange() {

    },
  };

  return (
    <>
      <div className="site-card-border-less-wrapper">
        <Card title="Upload Invoice" bordered={false} style={{ width: "99%" }}>
          <Row gutter={[24, 0]}>

            <Col span={24}>
     
              <Dragger {...dragger_props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag files to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
            </Col>
            {/* <Col span={12}>
              <h3>Select Multi Invoice</h3>
              <Upload {...props_multi_invoice} >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Col>
          
            <Col span={12}>
              <h3>PAN</h3>
              <Input value={pan} onChange={e=>{
                setPAN(e.target.value)
              }} placeholder='Enter PAN'></Input>
            </Col>

            <Col span={12}>
              <h3>Select Invoice type</h3>
             
              <Select size="large" defaultValue="Options.." style={{ width: "100%" }} onChange={handleChange}>
                <Option value="Non GST bill ">
  
                Non GST bill 
       
                 </Option>
                <Option value="GST bill">
                                   
                GST bill

                </Option>
              </Select>

              <p style={{ color: 'gray', fontSize: '12px' }}>
                <ul style={{listStyle:"none"}}>
                  <li>Note :</li>
                  <li>1. Select "No GST Bill" option if no GST is printed on the bill/invoice.</li>
                  <li>2. Select "GST Bill" option if GST is printed on the bill/invoice</li>
                </ul>
              </p>
            </Col> */}
          </Row>



          {/* <div>
            <Button style={{ marginTop: "50px" }}
              className='bob_color'
              onClick={
                async () => {
                  setIsLoading(true)
                  try {
                    const formData = new FormData();
                    formData.append('file', multiinvoice?multiinvoice:invoice);
                    formData.append('invoice_type', invoiceType);
                    formData.append('entity_pan', pan);
                    let url = multiinvoice?"/api/multi-upload":"/api/upload"
                    const response = await axios.post(url, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    });
                    toast.success("Submitted Successfully!");
                    setIsLoading(false)
                    console.log(response); // Success message

                  } catch (error) {
                    setIsLoading(false)
                    toast.error("Error occurred while submitting");
                    console.error(error); // Error message
                  }

                }
              }
              type="primary">{isLoading ? <Spin indicator={<LoadingOutlined style={{ color: 'white' }} />} /> : 'Submit'}</Button>
          </div> */}

        </Card>
      </div >
    </>
  )
}

export default UploadInvoice