import { AgGridReact } from 'ag-grid-react';
import { Input, Tag, Button, Modal, Card, Row, Col, Table, Select, InputNumber, DatePicker } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import DebounceSelect from '../../components/DebounceSelect';
import axios from 'axios';
import { approvalDataInitialState, defaultColDefProps } from './constants';
import { fetchVendorList, formatDate } from './methods';
import { toast } from 'react-toastify';

interface VendorValue {
  label: string;
  value: string;
}

// Define the column definitions for the grid

function InvoiceManagementPage() {

  const { Option } = Select;
  const gridRef = useRef<AgGridReact>(null);
  const [gridApi, setGridApi] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [amountFilter, setAmountFilter] = useState(null);
  const [vendorValue, setVendorValue] = useState<VendorValue[]>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [modelData, setModelData] = useState<any>()
  const [isDataCorrected, setIsDataCorrected] = useState(false)
  const [pdfURL, setPdfURL] = useState('')
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState('')
  const [isExtractedDataLoading, setExtractedDataLoading] = useState(true)
  const [ifControlZero, setIfControlZero] = useState(true)
  const [approveActive, setApproveActive] = useState(false)
  const [currentInvoiceId, setCurrentInvoiceId] = useState<any>()
  const [approvalData, setApprovalData] = useState<any>(approvalDataInitialState);
  const defaultColDef = useMemo(() => (defaultColDefProps), []);

  useEffect(() => {
    axios.post('/api/get-invoice-report').then(response => {
      console.log(response.data.data)
      setInvoiceData(response.data.data.map(formatDate))
    }).catch(err => {
      console.log(err)
    })
  }, [modalVisible])

  const columnDefs = [
    { headerName: 'S.no', width: 75, field: '', sortable: false, valueGetter: 'node.rowIndex + 1' },
    { headerName: 'invoice_id', field: 'invoice_id', sortable: true, hide: true },
    { headerName: 'Invoice Type', field: 'invoice_type', sortable: true },
    { headerName: 'Vendor Name', field: 'vendor_name', sortable: true },
    { headerName: 'Invoice Value', field: "invoice_value", sortable: true },
    { headerName: 'Uploaded by', field: 'name', sortable: true },
    { headerName: 'Upload date', field: 'submitted_on', sortable: true },
    {
      headerName: 'Status',
      field: 'invoice_status',
      sortable: true,
      cellRendererFramework: ({ value }: { value: string }) => (
        <Tag color={value === 'Pending' ? 'red' : value === 'Approved' ? 'green' : 'orange'}>{value}</Tag>
      ),
    },
    {
      headerName: 'View Invoice',
      cellRendererFramework: (params: any) => (
        <>
          <Button
            type="link"
            style={{ color: 'grey' }}
            onClick={() => {
              setModalVisible(true)
              let data = {
                invoice_id: params.data.invoice_id,
              }
              setCurrentInvoiceId(params.data.invoice_id)
              axios.post("/api/get-extracted-invoice-data", data).then(
                response => {
                  setExtractedDataLoading(false)
                  let modelDataArr = []
                  let approveDataArr = []
                  console.log("extracted data", response.data)
                  setPdfURL(response.data.data['file_url'])
                  for (let key in response.data.data) {
                    console.log(key)
                    if (key == "invoice_status") {
                      setSelectedInvoiceStatus(response.data.data[key])
                    }
                    if (key === "id" || key === "file_refrence_id" || key === "file_url" || key === "invoice_id" ) {
                      continue
                    }

                    modelDataArr.push(
                      {
                        key: key,
                        label: key,
                        value: response.data.data[key],
                      }
                    )
                    if (response.data.data[key] == "Pending approval") {

                    }
                    switch (key) {
                      case 'taxable_value':
                        approveDataArr.push({
                          particulars: "Taxable Value",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],
                        })
                        break
                      case 'gst':
                        approveDataArr.push({
                          particulars: "GST Value",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      case 'others':
                        approveDataArr.push({
                          particulars: "Others",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      case 'invoice_value':
                        approveDataArr.push({
                          particulars: "Invoice Value",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      case 'tds':
                        approveDataArr.push({
                          particulars: "TDS",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      case 'gst_tds':
                        approveDataArr.push({
                          particulars: "GST TDS",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      case 'total_tax':
                        approveDataArr.push({
                          particulars: "Total Tax",
                          approvedAmt: response.data.data[key],
                          pendingAmt: 0,
                          rejectedAmt: 0,
                          control: 0,
                          totalAmt: response.data.data[key],

                        })
                        break;

                      default:
                        break;
                    }
                  }

                  setModelData(modelDataArr)
                  setApprovalData(approveDataArr)
                  console.log(response.data.data)
                }
              )
              console.log(params)
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
          </Button>

        </>
      ),
    },
  ];

  const columnApproval = [
    {
      title: "Particulars",
      dataIndex: "particulars",
      key: "particulars",
      width: "20%",
    },
    {
      title: "Approved Amt",
      dataIndex: "approvedAmt",
      key: "approvedAmt",
      width: "20%",
      render: (record: any, rowData: any) => {
        return (<Input value={record} onChange={(e: any) => { updateAmounts(rowData.particulars, e.target.value, "approvedAmt") }} style={{ height: "30px", margin: 0, width: '150px' }}></Input>)
      }
    },
    {
      title: "Pending Amt",
      dataIndex: "pendingAmt",
      key: "pendingAmt",
      width: "20%",
      render: (record: any, rowData: any) => (<Input onChange={(e: any) => { updateAmounts(rowData.particulars, e.target.value, "pendingAmt") }} value={record} style={{ height: "30px", margin: 0, width: '150px' }}></Input>)
    },
    {
      title: "Rejected Amt",
      dataIndex: "rejectedAmt",
      key: "rejectedAmt",
      width: "20%",
      render: (record: any, rowData: any) => (<Input onChange={(e: any) => { updateAmounts(rowData.particulars, e.target.value, "rejectedAmt") }} value={record} style={{ height: "30px", margin: 0, width: '150px' }}></Input>)
    },
    {
      title: "Control",
      key: "control",
      width: "20%",
      dataIndex: "control",

    }
  ];

  const columnsModelTable = [
    {
      title: 'Details',
      dataIndex: '',
      key: 'details',
      render: (record: any) => (
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "10px" }}>
            <span>{record.label} :</span> <Input value={record.value} onChange={
              (e: any) => {
                // console.log(e.target.value);
                // record.value = e.target.value
                // let tempModalData = [...modelData]
                // tempModalData.forEach((item: any, index: number) => {
                //   if (item.label === record.label) {
                //     tempModalData[index]["value"] = e.target.value
                //     setIsDataCorrected(true)
                //   }
                // })
                // setModelData(tempModalData)
              }
            } style={{ width: "60%", height: "30px" }} />
          </div>
        </>
      ),
    },
  ];

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleAmountChange = (values: any) => {
    setAmountFilter(values)
  }

  const updateAmounts = (particular: string, newVal: any, columnName: string) => {
    console.log("updateAmounts", particular, newVal)
    let currentState = [...approvalData]
    currentState.forEach((item: any, idx: number) => {
      if (item.particulars === particular) {
        currentState[idx][columnName] = newVal
        currentState[idx]["control"] = (parseFloat(currentState[idx]["totalAmt"]) - parseFloat(currentState[idx]["pendingAmt"]) - parseFloat(currentState[idx]["rejectedAmt"]) - parseFloat(currentState[idx]["approvedAmt"])).toFixed(2);
        if (parseFloat(currentState[idx]["control"]) === 0) {
          setIfControlZero(true)
        } else {
          setIfControlZero(false)
        }
      }
    })
    console.log(currentState)
    setApprovalData(currentState)
  }

  return (
    <>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={(
          <>
            {!approveActive ? (
              <>
                <Button onClick={() => { setApproveActive(true) }} style={{ marginRight: 16, borderColor: "green", color: "green" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Next</span>
                    <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
                  </div>
                </Button>
                {selectedInvoiceStatus === "Pending approval" ? (
                  <Button style={{ borderColor: "red", color: "red" }} >
                    Reject complete
                  </Button>
                ) : (
                  <></>
                )}

              </>
            ) : (
              <>
                <Button onClick={() => { setApproveActive(false) }} style={{ marginRight: 16, borderColor: "green", color: "green" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                    <span>Back</span>
                  </div>
                </Button>
                <Button style={{ borderColor: "#F4811F", color: "#F4811F" }} onClick={() => {
                  if (!ifControlZero) {
                    toast.error("Please Make the control to 0")
                    return
                  }
                  axios.post('/api/submit-approval-invoice', { data: approvalData, invoice_id: currentInvoiceId }).then((response) => {
                    toast.success("Sunmitted successfully")
                    setModalVisible(false)
                  }).catch((error) => {
                    console.log("error 500 ", error)
                    toast.error("Error 500 !")
                  })

                }
                }>
                  Submit
                </Button>
              </>

            )}

          </>
        )}
        width="70vw"
        style={{ marginTop: "-70px" }}
      >
        <div style={{ height: "80vh" }}>
          <Row gutter={16} style={{ height: '100%' }}>
            {!approveActive ? (
              <>
                <Col span={14} style={{ overflow: "hidden" }}>
                  <object
                    data={pdfURL}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  ></object>
                </Col>
                <Col span={10}>
                  <Table dataSource={modelData} columns={columnsModelTable} pagination={false} bordered={true} style={{ marginTop: "20px" }} />
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>

                  <Table dataSource={approvalData} columns={columnApproval} pagination={false} />
                </Col>

              </>
            )}
          </Row>
        </div>
      </Modal>

      <Card>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
          <h2 style={{ marginTop: 0, marginBottom: 0 }}>Invoice <span style={{ color: "grey", fontWeight: 400 }}>Management</span></h2>
          <div style={{ width: "82%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

            <Select
              filterOption={false}
              suffixIcon={null}
              style={{ marginLeft: '8px', marginRight: '8px', width: "150px" }}
              placeholder="Invoice status">
              <Option value="Approved">Approved</Option>
              <Option value="PAPR">PAPR</Option>
              <Option value="PAPP">PAPP</Option>
              <Option value="Rejected">Rejected</Option>
              <Option value="Pending">Pending</Option>
              {/* map over the fetched vendor options and render as Select.Option */}
            </Select>

            <DebounceSelect
              mode="multiple"
              value={vendorValue}
              placeholder="Select Vendor"
              fetchOptions={fetchVendorList}
              onChange={(newValue) => {
                setVendorValue(newValue as VendorValue[]);
              }}
              style={{ marginLeft: '8px', marginRight: '8px', width: "350px" }}
            />

            <label style={{ marginLeft: '18px', marginRight: '8px', display: "flex", alignItems: "center" }}>
              Invoice Value :
              <InputNumber
                style={{ marginLeft: '8px', marginRight: '8px' }}
                placeholder="Min"
                onKeyDown={() => { }}
                onChange={(value) => handleAmountChange([value, amountFilter?.[1]])}
              />
              <InputNumber
                style={{ marginLeft: '8px', marginRight: '8px' }}
                placeholder="Max"
                onChange={(value) => handleAmountChange([amountFilter?.[0], value])}
              />
            </label>

          </div>
        </div>
        <div className="ag-theme-material" style={{ width: 'auto', height: "70vh" }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={invoiceData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            rowHeight={30}
            headerHeight={30}
            onGridReady={onGridReady}
          />
        </div>
      </Card>
    </>
  );
}

export default InvoiceManagementPage;
