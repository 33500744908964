import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

export interface ReportSliceInterface {
  value: number,
  activePRFilters: any,
  active2bFilters: any,
  activeMappedFilters: any,

  activeInvoiceWiseFilters: any,
  activeGstinWiseFilters: any,

  fetchedPRReportData: any,
  fetched2bReportData: any,
  fetchedMappedReportData: any,

  fetchedGstinWiseReport: any,
  fetchedInvoiceWiseReport: any,

  activePRFy: string,
  active2bFy: string,
  activeMappedFy: string,

  activeGstinWiseFy: string,
  activeInvoiceWiseFy: string,

  totalPagesPR: number,
  totalPages2b: number,
  totalPagesMapped: number,

  totalPagesInvoiceWise: number,
  totalPagesGstinWise: number,

  activeExcess2bTab:string,

  totalInvoiceCountPR: number,
  taxableValueSumPR: number,
  CGSTSumPR: number,
  IGSTSumPR: number,
  SGSTSumPR: number,
  totalGSTSumPR: number,
  totalInvoiceCountMapped: number,

  totalInvoiceCount2b: number,
  taxableValueSum2b: number,
  CGSTSum2b: number,
  IGSTSum2b: number,
  SGSTSum2b: number,
  totalGSTSum2b: number,

}

const initialState: ReportSliceInterface = {
  value: 0,
  activePRFilters: [],
  active2bFilters: [],
  activeMappedFilters: [],
  activeInvoiceWiseFilters: [],
  activeGstinWiseFilters: [],
  fetchedPRReportData: [],
  fetched2bReportData: [],
  fetchedMappedReportData: [],
  fetchedGstinWiseReport:[],
  fetchedInvoiceWiseReport:[],
  activePRFy: "",
  active2bFy: "",
  activeMappedFy: "",
  activeGstinWiseFy: "",
  activeInvoiceWiseFy: "",
  totalPagesPR: 0,
  totalPages2b: 0,
  totalPagesMapped: 0,
  totalInvoiceCountMapped: 0,

  
  activeExcess2bTab:"gstin_wise",

  totalInvoiceCountPR: 0,
  totalPagesInvoiceWise: 0,
  totalPagesGstinWise: 0,
  taxableValueSumPR: 0,
  CGSTSumPR: 0,
  IGSTSumPR: 0,
  SGSTSumPR: 0,
  totalGSTSumPR: 0,

  totalInvoiceCount2b: 0,
  taxableValueSum2b: 0,
  CGSTSum2b: 0,
  IGSTSum2b: 0,
  SGSTSum2b: 0,
  totalGSTSum2b: 0,

}

export const ReportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // Pushing Report Data
    pushPRReportData: (state, action: PayloadAction<{ data: any, totalPages: number, totalInvoiceCount: number, taxableValueSum: number, totalGSTSum: number, IGSTSum: number, SGSTSum: number, CGSTSum: number }>) => {
      state.fetchedPRReportData = [...action.payload.data]
      state.totalPagesPR = action.payload.totalPages
      state.totalInvoiceCountPR = action.payload.totalInvoiceCount
      state.IGSTSumPR = action.payload.IGSTSum
      state.SGSTSumPR = action.payload.SGSTSum
      state.CGSTSumPR = action.payload.CGSTSum
      state.totalGSTSumPR = action.payload.totalGSTSum
      state.taxableValueSumPR = action.payload.taxableValueSum
    },
    pushMappedReportData: (state, action: PayloadAction<{ data: any, totalPages: number , totalInvoiceCount: number}>) => {
      state.fetchedMappedReportData = [...action.payload.data]
      state.totalPagesMapped = action.payload.totalPages
      state.totalInvoiceCountMapped = action.payload.totalInvoiceCount
    },
    push2bReportData: (state, action: PayloadAction<{ data: any, totalPages: number, totalInvoiceCount: number, taxableValueSum: number, totalGSTSum: number, IGSTSum: number, SGSTSum: number, CGSTSum: number }>) => {
      state.fetched2bReportData = [...action.payload.data]
      state.totalPages2b = action.payload.totalPages
      state.totalInvoiceCount2b = action.payload.totalInvoiceCount
      state.IGSTSum2b = action.payload.IGSTSum
      state.SGSTSum2b = action.payload.SGSTSum
      state.CGSTSum2b = action.payload.CGSTSum
      state.totalGSTSum2b = action.payload.totalGSTSum
      state.taxableValueSum2b = action.payload.taxableValueSum
    },
    pushInvoiceWiseData: (state, action: PayloadAction<{ data: any, totalPages: number, totalInvoiceCount?: number, taxableValueSum?: number, totalGSTSum?: number, IGSTSum?: number, SGSTSum?: number, CGSTSum?: number }>) => {
      state.fetchedInvoiceWiseReport = [...action.payload.data]
      state.fetchedGstinWiseReport=[]
      state.totalPagesInvoiceWise = action.payload.totalPages
    },
    pushGstinWiseData: (state, action: PayloadAction<{ data: any, totalPages: number, totalInvoiceCount?: number, taxableValueSum?: number, totalGSTSum?: number, IGSTSum?: number, SGSTSum?: number, CGSTSum?: number }>) => {
      state.fetchedGstinWiseReport = [...action.payload.data]
      state.fetchedInvoiceWiseReport=[]
      state.totalPagesGstinWise = action.payload.totalPages
    },

    // Adding or Updating Filters
    addPRFilters: (state, action: PayloadAction<{ filter_on: string, filter_type: string, query: string }>) => {
      let currentFilter = [...current(state.activePRFilters)]
      currentFilter.push(action.payload)
      state.activePRFilters = currentFilter
    },
    updatePRFilters: (state, action: PayloadAction<{ filters: any }>) => {
      state.activePRFilters = action.payload.filters
    },
    add2bFilters: (state, action: PayloadAction<{ filter_on: string, filter_type: string, query: string }>) => {
      let currentFilter = [...current(state.active2bFilters)]
      currentFilter.push(action.payload)
      state.active2bFilters = currentFilter
    },
    update2bFilters: (state, action: PayloadAction<{ filters: any }>) => {
      state.active2bFilters = action.payload.filters
    },
    addMappedFilters: (state, action: PayloadAction<{ filter_on: string, filter_type: string, query: string }>) => {
      let currentFilter = [...current(state.activeMappedFilters)]
      currentFilter.push(action.payload)
      state.activeMappedFilters = currentFilter
    },
    updateMappedFilters: (state, action: PayloadAction<{ filters: any }>) => {
      state.activeMappedFilters = action.payload.filters
    },
    updateInvoiceWiseFilters: (state, action: PayloadAction<{ filters: any }>) => {
      state.activeInvoiceWiseFilters = action.payload.filters
    },
    updateGstinWiseFilters: (state, action: PayloadAction<{ filters: any }>) => {
      state.activeGstinWiseFilters = action.payload.filters
    },
    addInvoiceWiseFilters: (state, action: PayloadAction<{ filter_on: string, filter_type: string, query: string }>) => {
      let currentFilter = [...current(state.activeInvoiceWiseFilters)]
      currentFilter.push(action.payload)
      state.activeInvoiceWiseFilters = currentFilter
    },
    addGstinWiseFilters: (state, action: PayloadAction<{ filter_on: string, filter_type: string, query: string }>) => {
      let currentFilter = [...current(state.activeGstinWiseFilters)]
      currentFilter.push(action.payload)
      state.activeGstinWiseFilters = currentFilter
    },

    // Updating Active Fiscal Year
    updateActive2bFY: (state, action: PayloadAction<{ fy: string }>) => {
      state.active2bFy = action.payload.fy
    },
    updateActivePRFY: (state, action: PayloadAction<{ fy: string }>) => {
      state.activePRFy = action.payload.fy
    },
    updateActiveMappedFY: (state, action: PayloadAction<{ fy: string }>) => {
      state.activeMappedFy = action.payload.fy
    },
    updateActiveInvoiceWiseFY: (state, action: PayloadAction<{ fy: string }>) => {
      state.activeInvoiceWiseFy = action.payload.fy
    },
    updateActiveGstinWiseFY: (state, action: PayloadAction<{ fy: string }>) => {
      state.activeGstinWiseFy = action.payload.fy
    },

    // Other Functions
    increment: (state) => {
      state.value += 1
    },
    updateActiveExcess2bTab:((state, action: PayloadAction<{ activeTab: string}>)=>{
        state.activeExcess2bTab=action.payload.activeTab
    })
  },
})


// Action creators are generated for each case reducer function
export const { 
  increment, 
  addPRFilters, 
  addMappedFilters, 
  pushPRReportData, 
  pushMappedReportData, 
  updateActivePRFY, 
  updatePRFilters, 
  updateMappedFilters,
  add2bFilters, 
  push2bReportData, 
  updateActive2bFY, 
  update2bFilters,
  updateActiveMappedFY,
  updateActiveExcess2bTab,

  updateInvoiceWiseFilters,
  pushGstinWiseData,
  pushInvoiceWiseData,
  addInvoiceWiseFilters,
  addGstinWiseFilters,
  updateActiveInvoiceWiseFY,
  updateActiveGstinWiseFY
} = ReportSlice.actions;


export default ReportSlice.reducer