/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { DivWithLayoutProps } from "../interface/divWithLayout";

import {
  Row,
  Col,
  Badge,
  Dropdown,
  Button,
  Tabs,
  Select,
  Input,
  DatePicker,
  Spin,
  Space,
} from "antd";
import {
  SmileOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  addPRFilters,
  add2bFilters,
  addMappedFilters,
  pushPRReportData,
  push2bReportData,
  pushMappedReportData,
  updateActivePRFY,
  updateActive2bFY,
  updateActiveMappedFY,
  pushInvoiceWiseData,
  updateActiveInvoiceWiseFY,
  addInvoiceWiseFilters,
  pushGstinWiseData,
  updateActiveGstinWiseFY,
  addGstinWiseFilters,
} from "../features/Reports/reportSlice";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bobLogo from "../assets/images/boblogo2.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <span style={{ color: "green" }}>
        Data extracted succesfully from the file name xxx.jpeg
      </span>
    ),
  },
  {
    key: "2",
    label: (
      <span style={{ color: "red" }}>
        Data extracted succesfully from the file name xxx.jpeg
      </span>
    ),
  },
];

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ onPress }: any | DivWithLayoutProps) {
  const activePRFilters = useSelector(
    (state: any) => state.report.activePRFilters
  );
  const active2bFilters = useSelector(
    (state: any) => state.report.active2bFilters
  );
  const activeMappedFilters = useSelector(
    (state: any) => state.report.activeMappedFilters
  );
  const activePRFy = useSelector((state: any) => state.report.activePRFy);
  const active2bFy = useSelector((state: any) => state.report.active2bFy);
  const activeMappedFy = useSelector(
    (state: any) => state.report.activeMappedFy
  );
  const fetchedPRReportData = useSelector(
    (state: any) => state.report.fetchedPRReportData
  );
  const fetched2bReportData = useSelector(
    (state: any) => state.report.fetched2bReportData
  );
  const fetchedMappedReportData = useSelector(
    (state: any) => state.report.fetchedMappedReportData
  );
  const fetchedInvoiceWiseReport = useSelector(
    (state: any) => state.report.fetchedInvoiceWiseReport
  );
  const activeInvoiceWiseFilters = useSelector(
    (state: any) => state.report.activeInvoiceWiseFilters
  );
  const activeInvoiceWiseFy = useSelector(
    (state: any) => state.report.activeInvoiceWiseFy
  );

  const fetchedGstinWiseReport = useSelector(
    (state: any) => state.report.fetchedGstinWiseReport
  );
  const activeGstinWiseFilters = useSelector(
    (state: any) => state.report.activeGstinWiseFilters
  );

  const activeGstinWiseFy = useSelector(
    (state: any) => state.report.activeGstinWiseFy
  );
  const activeExcess2bTab = useSelector(
    (state: any) => state.report.activeExcess2bTab
  );
  const [reportPRTab, setReportPRTab] = useState(false);
  const [reportMappedTab, setReportMappedTab] = useState(false);

  const [filterByOptions, setFilterByOptions] = useState([
    { label: "Category", value: "match_category" },
    { label: "Vendor gstin", value: "vendor_gstin" },
    { label: "Invoice Date", value: "invoice_date" },
    { label: "Total GST", value: "total_gst" },
    { label: "Bank state code", value: "bank_state_code" },
    { label: "Invoice No", value: "invoice_number" },
    { label: "Pan", value: "pan" },
  ]);

  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { label: "Contains", value: "contains" },
    { label: "Equals", value: "equals" },
  ]);

  const [activeFilterQuery, setActiveFilterQuery] = useState<string>("");
  const [activeFilterBy, setActiveFilterBy] = useState("");
  const [activeFilterType, setActiveFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    let updatedFilterTypes = [];
    setActiveFilterType("");
    console.log(activeFilterBy)
    switch (activeFilterBy) {
      case "match_category":
      case "considered_gstin":
      case "supplier_gstin":
      case "business_gstin":
      case "bank_state_code":
      case "invoice_number":
      case "vendor_gstin":
      case "pan":
      case "category":
      case "pr_rin":
      case "pr_vendorgstin":
      case "pr_invoicenumber":
      case "2b_rin":
      case "2b_vendorgstin":
      case "2b_invoicenumber":
      case "category":
      case "doc_no":
        updatedFilterTypes = [
          { label: "Contains", value: "contains" },
          { label: "Equals", value: "equals" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      case "total_gst":
        
        updatedFilterTypes = [
          { label: "Equals", value: "equals" },
          { label: "Greater then", value: "greater_then" },
          { label: "Less then", value: "less_then" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      case "invoice_date":
      case "pr_invoicedate":
      case "2b_invoicedate":
      case "doc_date":
        updatedFilterTypes = [
          { label: "Equals", value: "equals" },
          { label: "After", value: "greater_then" },
          { label: "Before", value: "less_then" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      default:
        break;
    }
  }, [activeFilterBy]);
  useEffect(() => window.scrollTo(0, 0));
  useEffect(() => {
    console.log("Path name", window.location.pathname);
    if (window.location.pathname === "/report/pr") {
      setReportPRTab(true);
      setFilterByOptions([
        { label: "Category", value: "match_category" },
        { label: "Vendor gstin", value: "vendor_gstin" },
        { label: "Invoice Date", value: "invoice_date" },
        { label: "Total GST", value: "total_gst" },
        { label: "Bank state code", value: "bank_state_code" },
        { label: "Invoice No", value: "invoice_number" },
        { label: "Pan", value: "pan" },
      ]);
    }else if(window.location.pathname === "/report/mapped-records") {
      setReportPRTab(false);
      setReportMappedTab(false);
      setFilterByOptions([
        { label: "Category", value: "category" },
        { label: "PR - Rin", value: "pr_rin" },
        { label: "PR - Vendor GSTIN", value: "pr_vendorgstin" },
        { label: "PR - Invoice no", value: "pr_invoiceno" },
        { label: "PR - Invoice date", value: "pr_invoicedate" },
        { label: "2B - Rin", value: "2b_rin" },
        { label: "2B - Vendor GSTIN", value: "2b_vendorgstin" },
        { label: "2B - Invoice no", value: "2b_invoiceno" },
        { label: "2B - Invoice date", value: "2b_invoicedate" },
      ]
      );
    }
    else if(window.location.pathname === "/report/excess-2b") {
      setReportPRTab(false);
      setReportMappedTab(false);
      if(activeExcess2bTab=="gstin_wise"){

        setFilterByOptions(
          [
            { label: "Considered GSTIN", value: "considered_gstin" },
            { label: "CGST 2B", value: "cgst_2b" },
            { label: "SGST 2B", value: "sgst_2b" },
            { label: "IGST 2B", value: "igst_2b" },
            { label: "CGST pr", value: "cgst_pr" },
            { label: "SGST pr", value: "sgst_pr" },
            { label: "IGST pr", value: "igst_pr" },
            { label: "CGST Excess", value: "cgst_excess" },
            { label: "SGST Excess", value: "sgst_excess" },
            { label: "IGST Excess", value: "igst_excess" },
          ]
        );

      }else{
        setFilterByOptions(
          [
            { label: "Category", value: "category" },
            { label: "Supplier GSTIN", value: "supplier_gstin" },
            { label: "Considered GSTIN", value: "considered_gstin" },
            { label: "Doc Date", value: "doc_date" },
            { label: "Total GST", value: "total_gst" },
            { label: "Doc No", value: "doc_no" },
          ]
        );

      }
    }
    else {
      setReportPRTab(false);
      setFilterByOptions([
        { label: "Category", value: "match_category" },
        { label: "Supplier gstin", value: "supplier_gstin" },
        { label: "Considered gstin", value: "considered_gstin" },
        { label: "Business gstin", value: "business_gstin" },
        { label: "Invoice Date", value: "invoice_date" },
        { label: "Total GST", value: "total_gst" },
        { label: "Bank state code", value: "bank_state_code" },
        { label: "Bill of entry no", value: "bill_of_entry_no" },
      ]);
    }
  }, [window.location.pathname, activeExcess2bTab ]);

  const userName = useSelector((state: any) => state.user.name);

  return (
    <>
    
      <Row gutter={[24, 0]}>
        <Col  md={6} sm={6} xs={6} >
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{
                textTransform: "capitalize",
                color: "#F15A29",
                fontSize: "22px",
              }}
            >
              <img src={bobLogo} style={{ width: "160px" }} alt="BoB" />
            </span>
          </div>
        </Col>
        <Col md={18} sm={18} xs={18} className="header-control">
          {/* <Badge size="small" count={2}>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Dropdown>
          </Badge>

          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button> */}

          <Link
            style={{
              marginLeft: 15,
              display: "flex",
              justifyContent: "space-between",
            }}
            to="/profile"
            className="btn-sign-in"
          >
            <span>{profile}</span>
            <span style={{ width: 120, marginTop: 2 }}>
              Welcome {userName}!
            </span>
          </Link>

          {fetchedPRReportData.length > 0 &&
          window.location.pathname === "/report/pr" ? (
            <>
              <label>
                <div
                  style={{
                    color: "#b3b3b3",
                    marginTop: "-20px",
                    marginRight: "20px",
                  }}
                >
                  Financial year
                </div>

                <Select
                  style={{ width: 150, height: 30 }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setIsFullScreenLoading(true);

                    reportPRTab
                      ? dispatch(updateActivePRFY({ fy: e }))
                      : dispatch(updateActive2bFY({ fy: e }));
                    axios
                      .post("/api/filter-report", {
                        active_filters: JSON.stringify(
                          reportPRTab ? activePRFilters : active2bFilters
                        ),
                        page: 1,
                        report_type: reportPRTab ? "pr" : "2b",
                        fy: e,
                      })
                      .then((response: any) => {
                        console.log(response);
                        setIsFullScreenLoading(false);
                        if (response.data.data.length === 0) {
                          toast(
                            `No Data Found in FY:${e}, Remove applied filter and try again`
                          );
                          return;
                        }
                        dispatch(
                          pushPRReportData({
                            data: response.data.data,
                            totalPages: response.data.total_count,
                            totalInvoiceCount: response.data.total_count,
                            CGSTSum: response.data.cgst_sum,
                            IGSTSum: response.data.igst_sum,
                            SGSTSum: response.data.sgst_sum,
                            totalGSTSum: response.data.total_gst_sum,
                            taxableValueSum: response.data.taxable_value_sum,
                          })
                        );
                      })
                      .catch((error: any) => {
                        console.error(error);
                      });
                  }}
                  placeholder="Select financial year *"
                  value={reportPRTab ? activePRFy : active2bFy}
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },
                  ]}
                />
              </label>
              <Button
                onClick={(e) => {
                  dispatch(
                    addPRFilters({
                      filter_on: activeFilterBy,
                      query: activeFilterQuery,
                      filter_type: activeFilterType,
                    })
                  );
                  let currentFilter = reportPRTab
                    ? [...activePRFilters]
                    : [...active2bFilters];
                  currentFilter.push({
                    filter_on: activeFilterBy,
                    query: activeFilterQuery,
                    filter_type: activeFilterType,
                    fy: reportPRTab ? activePRFy : active2bFy,
                  });
                  setIsLoading(true);
                  axios
                    .post("/api/filter-report", {
                      active_filters: JSON.stringify(currentFilter),
                      page: 1,
                      report_type: reportPRTab ? "pr" : "2b",
                      fy: reportPRTab ? activePRFy : active2bFy,
                    })
                    .then((response: any) => {
                      console.log(response);
                      setIsLoading(false);
                      if (response.data.data.length === 0) {
                        toast(
                          "No Data Found, remove current filter and try again"
                        );
                        return;
                      }
                      dispatch(
                        pushPRReportData({
                          data: response.data.data,
                          totalPages: response.data.total_count,
                          totalInvoiceCount: response.data.total_count,
                          CGSTSum: response.data.cgst_sum,
                          IGSTSum: response.data.igst_sum,
                          SGSTSum: response.data.sgst_sum,
                          totalGSTSum: response.data.total_gst_sum,
                          taxableValueSum: response.data.taxable_value_sum,
                        })
                      );
                    })
                    .catch((error: any) => {
                      console.error(error);
                    });
                }}
                className="bob_color"
                style={{
                  backgroundColor: "#F4811F",
                  color: "white",
                  marginRight: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "white" }} />}
                  />
                ) : (
                  "+ Add Filter"
                )}
              </Button>
              <label>
                {activeFilterBy === "invoice_date" ? (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Select Date
                    </div>

                    <DatePicker
                      onChange={(e: any) => {
                        try {
                          setActiveFilterQuery(e.format("YYYY-MM-DD"));
                        } catch (e) {}
                      }}
                      format="YYYY-MM-DD"
                      picker="date"
                      suffixIcon={null}
                      showTime={false}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Filter query
                    </div>
                    <Input
                      onChange={(e) => {
                        setActiveFilterQuery(e.target.value);
                      }}
                      value={activeFilterQuery}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                )}
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter type
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterType(e);
                  }}
                  options={filterTypeOptions}
                  value={activeFilterType}
                  showSearch
                />
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter by
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterBy(e);
                  }}
                  placeholder="Filter by"
                  options={filterByOptions}
                  value={activeFilterBy}
                  showSearch
                />
              </label>
            </>
          ) : (
            <></>
          )}

          {/* -------------------- */}

          {fetchedMappedReportData.length > 0 &&
          window.location.pathname === "/report/mapped-records" ? (
            <>
              <label>
                <div
                  style={{
                    color: "#b3b3b3",
                    marginTop: "-20px",
                    marginRight: "20px",
                  }}
                >
                  Financial year
                </div>

                <Select
                  style={{ width: 150, height: 30 }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setIsFullScreenLoading(true);
                    dispatch(updateActiveMappedFY({ fy: e }));
                    axios
                      .post("/api/filter-report", {
                        active_filters: JSON.stringify(activeMappedFilters),
                        page: 1,
                        report_type: "mapping",
                        fy: e,
                      })
                      .then((response: any) => {
                        console.log(response);
                        setIsFullScreenLoading(false);
                        if (response.data.data.length === 0) {
                          toast(
                            `No Data Found in FY:${e}, Remove applied filter and try again`
                          );
                          return;
                        }
                        dispatch(
                          pushMappedReportData({
                            data: response.data.data,
                            totalPages: response.data.total_count,
                            totalInvoiceCount: response.data.total_count,
                          })
                        );
                      })
                      .catch((error: any) => {
                        console.error(error);
                      });
                  }}
                  placeholder="Select financial year *"
                  value={activeMappedFy}
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },
                  ]}
                />
              </label>

              <Button
                onClick={(e) => {
                  dispatch(
                    addMappedFilters({
                      filter_on: activeFilterBy,
                      query: activeFilterQuery,
                      filter_type: activeFilterType,
                    })
                  );
                  let currentFilter = [...activeMappedFilters];
                  currentFilter.push({
                    filter_on: activeFilterBy,
                    query: activeFilterQuery,
                    filter_type: activeFilterType,
                    fy: activeMappedFy,
                  });
                  setIsLoading(true);
                  axios
                    .post("/api/filter-report", {
                      active_filters: JSON.stringify(currentFilter),
                      page: 1,
                      report_type: "mapping",
                      fy: activeMappedFy,
                    })
                    .then((response: any) => {
                      console.log(response);
                      setIsLoading(false);
                      if (response.data.data.length === 0) {
                        toast(
                          "No Data Found, remove current filter and try again"
                        );
                        return;
                      }
                      dispatch(
                        pushMappedReportData({
                          data: response.data.data,
                          totalPages: response.data.total_count,
                          totalInvoiceCount: response.data.total_count,
                        })
                      );
                    })
                    .catch((error: any) => {
                      console.error(error);
                    });
                }}
                className="bob_color"
                style={{
                  backgroundColor: "#F4811F",
                  color: "white",
                  marginRight: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "white" }} />}
                  />
                ) : (
                  "+ Add Filter"
                )}
              </Button>
              <label>
                {activeFilterBy === "pr_invoicedate" ||
                activeFilterBy === "2b_invoicedate" ? (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Select Date
                    </div>

                    <DatePicker
                      onChange={(e: any) => {
                        try {
                          setActiveFilterQuery(e.format("YYYY-MM-DD"));
                        } catch (e) {}
                      }}
                      format="YYYY-MM-DD"
                      picker="date"
                      suffixIcon={null}
                      showTime={false}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Filter query
                    </div>
                    <Input
                      onChange={(e) => {
                        setActiveFilterQuery(e.target.value);
                      }}
                      value={activeFilterQuery}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                )}
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter type
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterType(e);
                  }}
                  options={filterTypeOptions}
                  value={activeFilterType}
                  showSearch
                />
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter by
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterBy(e);
                  }}
                  placeholder="Filter by"
                  options={filterByOptions}
                  value={activeFilterBy}
                  showSearch
                />
              </label>
            </>
          ) : (
            <></>
          )}

          {/* -------------------- */}

          {fetchedInvoiceWiseReport.length > 0 &&
          window.location.pathname === "/report/excess-2b" ? (
            <>
              <label>
                <div
                  style={{
                    color: "#b3b3b3",
                    marginTop: "-20px",
                    marginRight: "20px",
                  }}
                >
                  Financial year
                </div>
                  
                <Select
                  style={{ width: 150, height: 30 }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setIsFullScreenLoading(true);
                    dispatch(updateActiveInvoiceWiseFY({ fy: e }));
                    
                    axios
                      .post("/api/filter-report", {
                        active_filters: JSON.stringify(activeInvoiceWiseFilters),
                        page: 1,
                        report_type: "invoice_wise",
                        fy: e,
                      })
                      .then((response: any) => {
                        console.log(response);
                        setIsFullScreenLoading(false);
                        if (response.data.data.length === 0) {
                          toast(
                            `No Data Found in FY:${e}, Remove applied filter and try again`
                          );
                          return;
                        }
                        
                        dispatch(
                          pushInvoiceWiseData({
                            data: response.data.data,
                            totalPages: response.data.total_count,
                            totalInvoiceCount: response.data.total_count,
                          })
                        );
                      })
                      .catch((error: any) => {
                        console.error(error);
                      });
                  }}
                  placeholder="Select financial year *"
                  value={activeInvoiceWiseFy}
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                  ]}
                />
              </label>

              <Button
                onClick={(e) => {
                  dispatch(
                    addInvoiceWiseFilters({
                      filter_on: activeFilterBy,
                      query: activeFilterQuery,
                      filter_type: activeFilterType,
                    })
                  );
                  let currentFilter = [...activeMappedFilters];
                  currentFilter.push({
                    filter_on: activeFilterBy,
                    query: activeFilterQuery,
                    filter_type: activeFilterType,
                    fy: activeMappedFy,
                  });
                  setIsLoading(true);
                  axios
                    .post("/api/filter-report", {
                      active_filters: JSON.stringify(currentFilter),
                      page: 1,
                      report_type: activeExcess2bTab,
                      fy: activeExcess2bTab=="gstin_wise"?activeGstinWiseFy:activeInvoiceWiseFy,
                    })
                    .then((response: any) => {
                      console.log(response);
                      setIsLoading(false);
                      if (response.data.data.length === 0) {
                        toast(
                          "No Data Found, remove current filter and try again"
                        );
                        return;
                      }
                      
                      dispatch(
                        pushInvoiceWiseData({
                          data: response.data.data,
                          totalPages: response.data.total_count,
                          totalInvoiceCount: response.data.total_count,
                        })
                      );
                    })
                    .catch((error: any) => {
                      console.error(error);
                    });
                }}
                className="bob_color"
                style={{
                  backgroundColor: "#F4811F",
                  color: "white",
                  marginRight: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "white" }} />}
                  />
                ) : (
                  "+ Add Filter"
                )}
              </Button>
              <label>
                {activeFilterBy === "pr_invoicedate" ||
                activeFilterBy === "2b_invoicedate" ? (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Select Date
                    </div>

                    <DatePicker
                      onChange={(e: any) => {
                        try {
                          setActiveFilterQuery(e.format("YYYY-MM-DD"));
                        } catch (e) {}
                      }}
                      format="YYYY-MM-DD"
                      picker="date"
                      suffixIcon={null}
                      showTime={false}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Filter query
                    </div>
                    <Input
                      onChange={(e) => {
                        setActiveFilterQuery(e.target.value);
                      }}
                      value={activeFilterQuery}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                )}
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter type
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterType(e);
                  }}
                  options={filterTypeOptions}
                  value={activeFilterType}
                  showSearch
                />
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter by
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterBy(e);
                  }}
                  placeholder="Filter by"
                  options={filterByOptions}
                  value={activeFilterBy}
                  showSearch
                />
              </label>
            </>
          ) : (
            <></>
          )}
         {/* -------------------- */}

         {fetchedGstinWiseReport.length > 0 &&
          window.location.pathname === "/report/excess-2b" ? (
            <>
              <label>
                <div
                  style={{
                    color: "#b3b3b3",
                    marginTop: "-20px",
                    marginRight: "20px",
                  }}
                >
                  Financial year
                </div>
                  
                <Select
                  style={{ width: 150, height: 30 }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setIsFullScreenLoading(true);
                    dispatch(updateActiveGstinWiseFY({ fy: e }));
                    
                    axios
                      .post("/api/filter-report", {
                        active_filters: JSON.stringify(activeGstinWiseFilters),
                        page: 1,
                        report_type: "invoice_wise",
                        fy: e,
                      })
                      .then((response: any) => {
                        console.log(response);
                        setIsFullScreenLoading(false);
                        if (response.data.data.length === 0) {
                          toast(
                            `No Data Found in FY:${e}, Remove applied filter and try again`
                          );
                          return;
                        }
                        
                        dispatch(
                          pushInvoiceWiseData({
                            data: response.data.data,
                            totalPages: response.data.total_count,
                            totalInvoiceCount: response.data.total_count,
                          })
                        );
                      })
                      .catch((error: any) => {
                        console.error(error);
                      });
                  }}
                  placeholder="Select financial year *"
                  value={activeInvoiceWiseFy}
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },
                  ]}
                />
              </label>

              <Button
                onClick={(e) => {
                  dispatch(
                    addInvoiceWiseFilters({
                      filter_on: activeFilterBy,
                      query: activeFilterQuery,
                      filter_type: activeFilterType,
                    })
                  );
                  let currentFilter = [...activeMappedFilters];
                  currentFilter.push({
                    filter_on: activeFilterBy,
                    query: activeFilterQuery,
                    filter_type: activeFilterType,
                    fy: activeMappedFy,
                  });
                  setIsLoading(true);
                  axios
                    .post("/api/filter-report", {
                      active_filters: JSON.stringify(currentFilter),
                      page: 1,
                      report_type: activeExcess2bTab,
                      fy: activeExcess2bTab=="gstin_wise"?activeGstinWiseFy:activeInvoiceWiseFy,
                    })
                    .then((response: any) => {
                      console.log(response);
                      setIsLoading(false);
                      if (response.data.data.length === 0) {
                        toast(
                          "No Data Found, remove current filter and try again"
                        );
                        return;
                      }
                      
                      dispatch(
                        pushGstinWiseData({
                          data: response.data.data,
                          totalPages: response.data.total_count,
                          totalInvoiceCount: response.data.total_count,
                        })
                      );
                    })
                    .catch((error: any) => {
                      console.error(error);
                    });
                }}
                className="bob_color"
                style={{
                  backgroundColor: "#F4811F",
                  color: "white",
                  marginRight: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "white" }} />}
                  />
                ) : (
                  "+ Add Filter"
                )}
              </Button>
              <label>
                {activeFilterBy === "pr_invoicedate" ||
                activeFilterBy === "2b_invoicedate" ? (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Select Date
                    </div>

                    <DatePicker
                      onChange={(e: any) => {
                        try {
                          setActiveFilterQuery(e.format("YYYY-MM-DD"));
                        } catch (e) {}
                      }}
                      format="YYYY-MM-DD"
                      picker="date"
                      suffixIcon={null}
                      showTime={false}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Filter query
                    </div>
                    <Input
                      onChange={(e) => {
                        setActiveFilterQuery(e.target.value);
                      }}
                      value={activeFilterQuery}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                )}
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter type
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterType(e);
                  }}
                  options={filterTypeOptions}
                  value={activeFilterType}
                  showSearch
                />
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter by
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterBy(e);
                  }}
                  placeholder="Filter by"
                  options={filterByOptions}
                  value={activeFilterBy}
                  showSearch
                />
              </label>
            </>
          ) : (
            <></>
          )}
          {/* -------------------- */}

          {fetched2bReportData.length > 0 &&
          window.location.pathname === "/report/2b" ? (
            <>
              <label>
                <div
                  style={{
                    color: "#b3b3b3",
                    marginTop: "-20px",
                    marginRight: "20px",
                  }}
                >
                  Financial year
                </div>

                <Select
                  style={{ width: 150, height: 30 }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setIsFullScreenLoading(true);
                    dispatch(updateActive2bFY({ fy: e }));
                    axios
                      .post("/api/filter-report", {
                        active_filters: JSON.stringify(active2bFilters),
                        page: 1,
                        report_type: "2b",
                        fy: e,
                      })
                      .then((response: any) => {
                        console.log(response);
                        setIsFullScreenLoading(false);
                        if (response.data.data.length === 0) {
                          toast(
                            `No Data Found in FY:${e}, Remove applied filter and try again`
                          );
                          return;
                        }
                        dispatch(
                          push2bReportData({
                            data: response.data.data,
                            totalPages: response.data.total_count,
                            totalInvoiceCount: response.data.total_count,
                            CGSTSum: response.data.cgst_sum,
                            IGSTSum: response.data.igst_sum,
                            SGSTSum: response.data.sgst_sum,
                            totalGSTSum: response.data.total_gst_sum,
                            taxableValueSum: response.data.taxable_value_sum,
                          })
                        );
                      })
                      .catch((error: any) => {
                        console.error(error);
                      });
                  }}
                  placeholder="Select financial year *"
                  value={reportPRTab ? activePRFy : active2bFy}
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },

                  ]}
                />
              </label>

              <Button
                onClick={(e) => {
                  dispatch(
                    add2bFilters({
                      filter_on: activeFilterBy,
                      query: activeFilterQuery,
                      filter_type: activeFilterType,
                    })
                  );
                  let currentFilter = [...active2bFilters];
                  currentFilter.push({
                    filter_on: activeFilterBy,
                    query: activeFilterQuery,
                    filter_type: activeFilterType,
                    fy: active2bFy,
                  });
                  setIsLoading(true);
                  axios
                    .post("/api/filter-report", {
                      active_filters: JSON.stringify(currentFilter),
                      page: 1,
                      report_type: "2b",
                      fy: active2bFy,
                    })
                    .then((response: any) => {
                      console.log(response);
                      setIsLoading(false);
                      if (response.data.data.length === 0) {
                        toast(
                          "No Data Found, remove current filter and try again"
                        );
                        return;
                      }
                      dispatch(
                        push2bReportData({
                          data: response.data.data,
                          totalPages: response.data.total_count,
                          totalInvoiceCount: response.data.total_count,
                          CGSTSum: response.data.cgst_sum,
                          IGSTSum: response.data.igst_sum,
                          SGSTSum: response.data.sgst_sum,
                          totalGSTSum: response.data.total_gst_sum,
                          taxableValueSum: response.data.taxable_value_sum,
                        })
                      );
                    })
                    .catch((error: any) => {
                      console.error(error);
                    });
                }}
                className="bob_color"
                style={{
                  backgroundColor: "#F4811F",
                  color: "white",
                  marginRight: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "white" }} />}
                  />
                ) : (
                  "+ Add Filter"
                )}
              </Button>
              <label>
                {activeFilterBy === "invoice_date" ? (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Select Date
                    </div>

                    <DatePicker
                      onChange={(e: any) => {
                        try {
                          setActiveFilterQuery(e.format("YYYY-MM-DD"));
                        } catch (e) {}
                      }}
                      format="YYYY-MM-DD"
                      picker="date"
                      suffixIcon={null}
                      showTime={false}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ color: "#b3b3b3", marginTop: "-18px" }}>
                      Filter query
                    </div>
                    <Input
                      onChange={(e) => {
                        setActiveFilterQuery(e.target.value);
                      }}
                      value={activeFilterQuery}
                      style={{
                        width: 120,
                        height: 32,
                        marginTop: 0,
                        marginRight: 12,
                      }}
                    />
                  </>
                )}
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter type
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterType(e);
                  }}
                  options={filterTypeOptions}
                  value={activeFilterType}
                  showSearch
                />
              </label>
              <label>
                <div style={{ color: "#b3b3b3", marginTop: "-20px" }}>
                  Filter by
                </div>

                <Select
                  style={{
                    width: 120,
                    height: 30,
                    marginTop: 0,
                    marginRight: 12,
                  }}
                  suffixIcon={null}
                  onChange={(e) => {
                    setActiveFilterBy(e);
                  }}
                  placeholder="Filter by"
                  options={filterByOptions}
                  value={activeFilterBy}
                  showSearch
                />
              </label>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {isFullScreenLoading ? (
        <StyledLoader>
          <Spin
            indicator={<LoadingOutlined style={{ color: "#F4811F" }} />}
            size="large"
          />
        </StyledLoader>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
