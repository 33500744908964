import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  Card,
  Tag,
  Button,
  Radio,
  Pagination,
  Select,
  Input,
  DatePicker,
  Tabs,
  Row,
  Col,
  Spin,
} from "antd";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
import { columnDefs2BReport } from "../constants/reportPageConstant";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  add2bFilters,
  push2bReportData,
  updateActive2bFY,
  update2bFilters,
} from "../features/Reports/reportSlice";

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;
function ReportsNewPage() {
  const gridRef = useRef<AgGridReact>(null);
  const pageSize = 100; // number of items per page
  const [gridApi, setGridApi] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [filterByOptions, setFilterByOptions] = useState([
    { label: "Category", value: "match_category" },
    { label: "Supplier gstin", value: "supplier_gstin" },
    { label: "Considered gstin", value: "considered_gstin" },
    { label: "Business gstin", value: "business_gstin" },
    { label: "Invoice Date", value: "invoice_date" },
    { label: "Total GST", value: "total_gst" },
    { label: "Bank state code", value: "bank_state_code" },
    { label: "Bill of entry no", value: "bill_of_entry_no" },
  ]);
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { label: "Contains", value: "contains" },
    { label: "Equals", value: "equals" },
  ]);
  const [activeFilterQuery, setActiveFilterQuery] = useState<string>("");
  const [activeFilterBy, setActiveFilterBy] = useState("");
  const [activeFilterType, setActiveFilterType] = useState("");
  const [activeFY, setActiveFY] = useState("");
  const [isReportFetched, setIsReportFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const active2bFy = useSelector((state: any) => state.report.active2bFy);
  const totalCount = useSelector((state: any) => state.report.totalPages2b);
  const active2bFilters = useSelector(
    (state: any) => state.report.active2bFilters
  );

  const totalInvoiceCount2b = useSelector(
    (state: any) => state.report.totalInvoiceCount2b
  );
  const taxableValueSum2b = useSelector(
    (state: any) => state.report.taxableValueSum2b
  );
  const CGSTSum2b = useSelector((state: any) => state.report.CGSTSum2b);
  const IGSTSum2b = useSelector((state: any) => state.report.IGSTSum2b);
  const SGSTSum2b = useSelector((state: any) => state.report.SGSTSum2b);
  const totalGSTSum2b = useSelector((state: any) => state.report.totalGSTSum2b);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      // Default Column Properties
    }),
    []
  );
  useEffect(() => {
    let updatedFilterTypes = [];
    setActiveFilterType("");
    switch (activeFilterBy) {
      case "match_category":
      case "considered_gstin":
      case "supplier_gstin":
      case "business_gstin":
      case "bank_state_code":
      case "invoice_number":
      case "vendor_gstin":
      case "pan":
        updatedFilterTypes = [
          { label: "Contains", value: "contains" },
          { label: "Equals", value: "equals" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      case "total_gst":
        updatedFilterTypes = [
          { label: "Equals", value: "equals" },
          { label: "Greater then", value: "greater_then" },
          { label: "Less then", value: "less_then" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      case "invoice_date":
        updatedFilterTypes = [
          { label: "Equals", value: "equals" },
          { label: "After", value: "greater_then" },
          { label: "Before", value: "less_then" },
        ];
        setFilterTypeOptions(updatedFilterTypes);
        break;

      default:
        break;
    }
  }, [activeFilterBy]);

  const getReport2b = () => {
    if (!activeFY) {
      toast.error("Please Select Financial year");
      return;
    }
    let data = {
      filter_on: "",
      query: "",
      filter_type: "",
      fy: activeFY,
    };
    if (activeFilterBy && activeFilterQuery && activeFilterQuery) {
      data = {
        filter_on: activeFilterBy,
        query: activeFilterQuery,
        filter_type: activeFilterType,
        fy: activeFY,
      };
    }
    setIsLoading(true);
    axios
      .post("/api/get-report-2b", data)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response.data.data.length > 0) {
          setIsReportFetched(true);
          dispatch(
            push2bReportData({
              data: response.data.data,
              totalPages: response.data.total_count,
              totalInvoiceCount: response.data.total_count,
              CGSTSum: response.data.cgst_sum,
              IGSTSum: response.data.igst_sum,
              SGSTSum: response.data.sgst_sum,
              totalGSTSum: response.data.total_gst_sum,
              taxableValueSum: response.data.taxable_value_sum,
            })
          );
          if (activeFilterBy && activeFilterQuery && activeFilterQuery) {
            dispatch(add2bFilters(data));
          } else {
            dispatch(updateActive2bFY({ fy: activeFY }));
          }

          return;
        } else {
          toast("No Data Found");
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Server side error occurred");
      });
  };
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  const handlePageChange = (page: any) => {
    setIsFullScreenLoading(true);
    axios
      .post("/api/filter-report", {
        active_filters: JSON.stringify(active2bFilters),
        page: page,
        report_type: "2b",
        fy: active2bFy,
      })
      .then((response: any) => {
        console.log(response);
        setIsFullScreenLoading(false);
        setCurrentPage(page);
        if (response.data.data.length === 0) {
          toast("No Data Found, remove current filter and try again");
          return;
        }
        dispatch(
          push2bReportData({
            data: response.data.data,
            totalPages: response.data.total_count,
            totalInvoiceCount: response.data.total_count,
            CGSTSum: response.data.cgst_sum,
            IGSTSum: response.data.igst_sum,
            SGSTSum: response.data.sgst_sum,
            totalGSTSum: response.data.total_gst_sum,
            taxableValueSum: response.data.taxable_value_sum,
          })
        );
      })
      .catch((error: any) => {
        setIsFullScreenLoading(false);
        toast.error("Error occurs");
        console.error(error);
      });
  };
  const reportData = useSelector(
    (state: any) => state.report.fetched2bReportData
  );
  const activeFilter = useSelector(
    (state: any) => state.report.active2bFilters
  );

  const downloadBinaryFile = (binaryData: any) => {
    const blob = new Blob([binaryData]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv");
    link.click();
  };
  const download2bReport = () => {
    setIsFullScreenLoading(true);
    axios
      .post("/api/download-report", {
        active_filters: JSON.stringify(active2bFilters),
        page: 1,
        fy: active2bFy,
        reportType: "2b",
      })
      .then((response) => {
        setIsFullScreenLoading(false);
        downloadBinaryFile(response.data);
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      <div className="layout-content">
        <Card style={{ padding: 0, minHeight: "85vh" }}>
          {reportData.length > 0 ? (
            <>
              {activeFilter.map((filter: any, index: number) =>
                filter.filter_on && filter.filter_type && filter.query ? (
                  <Tag
                    key={index}
                    closable
                    onClose={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      console.log(activeFilter);
                      let filterToRemove = activeFilter[index];
                      let newFilter: any = [];
                      activeFilter.forEach((filter_2: any) => {
                        if (
                          filter_2["filter_on"] !== filterToRemove["filter_on"]
                        ) {
                          newFilter.push(filter_2);
                        }
                      });
                      console.log(">> NEW >>", newFilter);
                      // gridApi.showLoadingOverlay();
                      dispatch(update2bFilters({ filters: newFilter }));
                      axios
                        .post("/api/filter-report", {
                          active_filters: JSON.stringify(newFilter),
                          page: 1,
                          report_type: "2b",
                          fy: active2bFy,
                        })
                        .then((response) => {
                          if (response.data.data.length > 0) {
                            dispatch(
                              push2bReportData({
                                data: response.data.data,
                                totalPages: response.data.total_count,
                                totalInvoiceCount: response.data.total_count,
                                CGSTSum: response.data.cgst_sum,
                                IGSTSum: response.data.igst_sum,
                                SGSTSum: response.data.sgst_sum,
                                totalGSTSum: response.data.total_gst_sum,
                                taxableValueSum:
                                  response.data.taxable_value_sum,
                              })
                            );
                          }
                          gridApi.hideOverlay();
                        })
                        .catch((error) => {
                          console.error(error);
                          gridApi.hideOverlay();
                        });
                      console.log(activeFilter);
                    }}
                    color="green"
                    style={{ marginBottom: 10, marginLeft: 10, float: "right" }}
                  >
                    {filter.filter_on} | {filter.filter_type} | FY:{filter.fy}
                  </Tag>
                ) : (
                  <></>
                )
              )}

              {activeFilter.length > 0 ? (
                <span style={{ color: "grey", float: "right" }}>
                  Active Filters :
                </span>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "15%",
                }}
              >
                <h2 style={{ fontWeight: 600, margin: "0 0 5px 0" }}>
                  2B Report{" "}
                </h2>{" "}
                <Button
                  onClick={(e) => {
                    download2bReport();
                  }}
                  style={{
                    width: 50,
                    height: 25,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 10,
                  color: "grey",
                }}
              >
                <b>Total Invoices :</b>{" "}
                {totalInvoiceCount2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
                <b style={{ marginLeft: 10 }}>Total Taxable Value :</b>{" "}
                {taxableValueSum2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
                <b style={{ marginLeft: 10 }}>IGST :</b>{" "}
                {IGSTSum2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
                <b style={{ marginLeft: 10 }}>SGST :</b>{" "}
                {SGSTSum2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
                <b style={{ marginLeft: 10 }}>CGST :</b>{" "}
                {CGSTSum2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
                <b style={{ marginLeft: 10 }}>Total GST :</b>{" "}
                {totalGSTSum2b?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
              </div>
              <div
                className="ag-theme-material"
                style={{ width: "100%", height: "70vh" }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={reportData} // Row Data for Rows
                  columnDefs={columnDefs2BReport} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection="multiple" // Options - allows click selection of rows
                  // Optional - registering for Grid Event
                  rowHeight={25}
                  headerHeight={30}
                  onGridReady={onGridReady}
                />
              </div>

              <Pagination
                style={{ marginTop: "20px" }}
                className="custom-pagination"
                hideOnSinglePage
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={handlePageChange}
                pageSizeOptions={[]}
              />
            </>
          ) : (
            // -------------------------------------------------------------------------------------------------------
            <>
              <h2 style={{ fontWeight: 600, margin: "0 0 5px 0" }}>
                2B Report{" "}
              </h2>

              <div style={{ width: "100%" }}>
                <Select
                  style={{ width: 320 }}
                  size="large"
                  onChange={(e) => {
                    setActiveFY(e);
                  }}
                  placeholder="Select financial year *"
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },
                  ]}
                />

                <h3 style={{ fontWeight: 500, margin: "30px 0 10px 0" }}>
                  Filters (Optional)
                </h3>
                <Row>
                  <Col span={8}>
                    <Select
                      style={{ width: 320, marginTop: 0 }}
                      size="large"
                      onChange={(e) => {
                        setActiveFilterBy(e);
                      }}
                      placeholder="Filter by"
                      options={filterByOptions}
                      showSearch
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      value={activeFilterType}
                      style={{ width: 320, marginTop: 0 }}
                      size="large"
                      onChange={(e) => {
                        setActiveFilterType(e);
                      }}
                      options={filterTypeOptions}
                      placeholder={
                        activeFilterType === "" ? "Select Filter Type" : ""
                      }
                    />
                  </Col>
                  <Col span={8}>
                    {activeFilterBy === "invoice_date" ? (
                      <DatePicker
                        onChange={(e: any) => {
                          try {
                            setActiveFilterQuery(e.format("YYYY-MM-DD"));
                          } catch (e) {}
                        }}
                        format="YYYY-MM-DD"
                        picker="date"
                        showTime={false}
                        size="large"
                      />
                    ) : (
                      <Input
                        placeholder="Filter query"
                        value={activeFilterQuery}
                        onChange={(e) => setActiveFilterQuery(e.target.value)}
                        size="large"
                      />
                    )}
                  </Col>
                </Row>

                <Button
                  style={{ marginTop: "50px", color: "white" }}
                  onClick={(e) => {
                    getReport2b();
                  }}
                  className="bob_color"
                >
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ color: "white" }} />}
                    />
                  ) : (
                    "Get Report"
                  )}
                </Button>
              </div>
            </>
          )}
        </Card>
      </div>
      {isFullScreenLoading ? (
        <StyledLoader>
          <Spin
            indicator={<LoadingOutlined style={{ color: "#F4811F" }} />}
            size="large"
          />
        </StyledLoader>
      ) : (
        <></>
      )}
    </>
  );
}

export default ReportsNewPage;