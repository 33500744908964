import React, { useState, useRef } from 'react'; // Required import for React
import { AgGridReact } from 'ag-grid-react'; // Required import for AgGridReact component
import { Pagination } from 'antd';
import { columnDefGstinWise, columnDefInvoiceWise } from '../constants/reportPageConstant';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import { addInvoiceWiseFilters, pushInvoiceWiseData, updateActiveInvoiceWiseFY } from '../features/Reports/reportSlice';


const Excess2BInvoiceWise = (props: any) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1); // State for current page of pagination
    const pageSize = 10; // Number of rows to show per page
    const [totalCount, setTotalCount] = useState(0); // State for total number of rows
    const gridRef = useRef(null); // Ref for accessing Grid's API
    const fetchedInvoiceWiseReport = useSelector(
      (state: any) => state.report.fetchedInvoiceWiseReport
    );

    const totalPagesInvoiceWise = useSelector(
      (state: any) => state.report.totalPagesInvoiceWise
    );
  
    const activeFY = useSelector(
      (state: any) => state.report.activeInvoiceWiseFy
    );

    // Default Column Properties
    const defaultColDef = {
      // your default column properties go here
      sortable: true,
      filter: false,
      cellStyle: {'text-align': 'left'},
      headerStyle: {'text-align': 'left'}
    };
  
    // Optional - registering for Grid Event
    const onGridReady = (params:any) => {
      gridRef.current = params.api;
    };
  
    // Function to handle page changes
    const handlePageChange = (pageNumber:number) => {
      setCurrentPage(pageNumber);

      let  data = {
          filter_on: props.activeFilterBy,
          query: props.activeFilterQuery,
          filter_type: props.activeFilterType,
          fy: activeFY,
          excess2b_type: "invoice_wise",
          page:pageNumber
        };
      
      props.setIsLoading(true);
      axios
        .post("/api/get-report-excess2b", data)
        .then((response) => {
          console.log(response);
          props.setIsLoading(false);
            if (response.data.data.length > 0) {
              dispatch(
                pushInvoiceWiseData({
                  data: response.data.data,
                  totalPages: response.data.total_count,
                  totalInvoiceCount: response.data.total_count,
                  CGSTSum: response.data.cgst_sum,
                  IGSTSum: response.data.igst_sum,
                  SGSTSum: response.data.sgst_sum,
                  totalGSTSum: response.data.total_gst_sum,
                  taxableValueSum: response.data.taxable_value_sum,
                })
              );
                
              dispatch(addInvoiceWiseFilters(data));
             
  
              return;
            }
          }
        )
        .catch((err) => {
          props.setIsLoading(false);
        });
    };
    
    return (
      <>
        <div className="ag-theme-material" style={{ width: "100% !important", height: "70vh" }}>
          <AgGridReact
            ref={gridRef}
            rowData={fetchedInvoiceWiseReport}
            columnDefs={columnDefInvoiceWise}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            rowHeight={25}
            headerHeight={30}
            onGridReady={onGridReady}
          />
        </div>
  
        <Pagination
          style={{ marginTop: "20px" }}
          className="custom-pagination"
          hideOnSinglePage
          current={currentPage}
          pageSize={pageSize}
          total={totalPagesInvoiceWise}
          onChange={handlePageChange}
          pageSizeOptions={[]}
        />
      </>
    )
}

export default Excess2BInvoiceWise