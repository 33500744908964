import React from 'react'

import '../assets/styles/loader.css'
function FullScreenLoader() {

  return (
    <div className="loader-container">
        <div className="group"> 
        <div className="bigSqr">
            <div className="square first"></div>
            <div className="square second"></div>
            <div className="square third"></div>
            <div className="square fourth"></div>
        </div>
        <div className="text">loading..</div>
        </div>
        
    </div>
  )
}

export default FullScreenLoader