import Card from "antd/es/card/Card";




function VendorFollowUp() {

    return (

        <div className="site-card-border-less-wrapper">
            <Card bordered={false} style={{ width: "99%" }}>

                UNDER DEVELOPMENT MNOE
            </Card>
        </div >
    );

}

export default VendorFollowUp
