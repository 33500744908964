/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import {
    Card,
    Col,
    Row,
    Select,
    Upload,
    Button,
    Input,

} from "antd";

import { UploadOutlined } from '@ant-design/icons';
import type { SelectProps } from 'antd';
import axios from "axios";
import { toast } from "react-toastify";

function AddVendorPage() {
    const [vendorName, setVendorName] = useState("")
    const [vendorEmail, setVendorEmail] = useState("")
    const [vendorCompany, setVendorCompany] = useState("")

    const options: SelectProps['options'] = [{value:"infosys",label:"Infosys"},{value:"SBI",label:"SBI"},{value:"BSNL",label:"BSNL"}];

    const handleChange = (value: string) => {
        setVendorCompany(value)
    };

    const addVendor=()=>{
        axios.post('/api/add-vendor', {
                vendor_name: vendorName,
                vendor_email: vendorEmail,
                vendor_company:vendorCompany
                })
                .then(response => {
                // handle success
                console.log(response.data);
                toast.success("Vendor added successfully!")
                })
                .catch(error => {
                // handle error
                console.log(error);
                });

    }
    return (
        <>
            <div className="layout-content">
                <Card title="Add Vendor" style={{ width: "95%" }}>
                    <Upload>
                        <Button icon={<UploadOutlined />}>Upload CSV</Button>
                    </Upload>
                    <div style={{ marginTop: "20px", textAlign: "center",color:"grey" }}>----- OR -----</div>

                    <Row gutter={16} style={{ marginTop: "20px" }}>
                        <Col className="gutter-row" span={7}>
                            <Input onChange={(e:any)=>{setVendorName(e.target.value)}} value={vendorName}  placeholder="Vendor name" />
                        </Col>
                        <Col className="gutter-row" span={7}>
                            <Input onChange={(e:any)=>{setVendorEmail(e.target.value)}} value={vendorEmail}  placeholder="Email" />
                        </Col>
                        <Col className="gutter-row" span={7}>
                            <Select
     
                                style={{ width: '100%' }}
                                placeholder="Select Vendor Company"
                                onChange={handleChange}
                                options={options}
                                size="large"
                            />
                        </Col>
                        <Col className="gutter-row" span={3}>

                    <Button style={{width:"100%"}} type="primary" onClick={()=>{addVendor()}} >Add Vendor</Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

export default AddVendorPage;
