import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  Card,
  Tag,
  Button,
  Radio,
  Pagination,
  Select,
  Input,
  DatePicker,
  Tabs,
  Row,
  Col,
  Spin,
} from "antd";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
import { columnDefMappedRecord } from "../constants/reportPageConstant";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";

import Excess2BGstinWise from "../components/excess2b-gstinwise";
import Excess2BInvoiceWise from "../components/excess2b-invoicewise";
import {
  updateActiveExcess2bTab,
  pushInvoiceWiseData,
  addInvoiceWiseFilters,
  updateActiveInvoiceWiseFY,
  addGstinWiseFilters,
  updateActiveGstinWiseFY,
  pushGstinWiseData,
} from "../features/Reports/reportSlice";
const { TabPane } = Tabs;
const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

function Excess2BPage() {
  const dispatch = useDispatch();
  const [filterByOptions, setFilterByOptions] = useState([
    { label: "Considered GSTIN", value: "considered_gstin" },
    { label: "CGST", value: "cgst" },
    { label: "SGST", value: "sgst" },
    { label: "IGST", value: "igst" },
  ]);
  const [filterTypeOptions, setFilterTypeOptions] = useState([
    { label: "Contains", value: "contains" },
    { label: "Equals", value: "equals" },
  ]);
  const [activeFilterQuery, setActiveFilterQuery] = useState<string>("");
  const [activeFilterBy, setActiveFilterBy] = useState("");
  const [activeFilterType, setActiveFilterType] = useState("");
  const [activeFY, setActiveFY] = useState("");
  const [isReportFetched, setIsReportFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const fetchedGstinWiseReport = useSelector(
    (state: any) => state.report.fetchedGstinWiseReport
  );
  const fetchedInvoiceWiseReport = useSelector(
    (state: any) => state.report.fetchedInvoiceWiseReport
  );
  const activeExcess2bTab = useSelector(
    (state: any) => state.report.activeExcess2bTab
  );

  useEffect(() => {
    if (activeExcess2bTab === "gstin_wise") {
      setFilterByOptions([
        { label: "Considered GSTIN", value: "considered_gstin" },
        { label: "CGST 2B", value: "cgst_2b" },
        { label: "SGST 2B", value: "sgst_2b" },
        { label: "IGST 2B", value: "igst_2b" },
        { label: "CGST pr", value: "cgst_pr" },
        { label: "SGST pr", value: "sgst_pr" },
        { label: "IGST pr", value: "igst_pr" },
        { label: "CGST Excess", value: "cgst_excess" },
        { label: "SGST Excess", value: "sgst_excess" },
        { label: "IGST Excess", value: "igst_excess" },
      ]);
    } else if (activeExcess2bTab === "invoice_wise") {
      setFilterByOptions([
        { label: "Category", value: "category" },
        { label: "Supplier GSTIN", value: "supplier_gstin" },
        { label: "Considered GSTIN", value: "considered_gstin" },
        { label: "Doc Date", value: "doc_date" },
        { label: "Total GST", value: "total_gst" },
        { label: "Doc No", value: "doc_no" },
      ]);
    }
  }, [activeExcess2bTab]);

  useEffect(() => {
    let updatedFilterTypes = [];

    if (activeFilterBy === "doc_date") {
      updatedFilterTypes = [
        { label: "Equals", value: "equals" },
        { label: "After", value: "greater_then" },
        { label: "Before", value: "less_then" },
      ];
      setFilterTypeOptions(updatedFilterTypes);
    } else if (
      activeFilterBy.includes("gst") &&
      activeFilterBy !== "cgst_excess" &&
      activeFilterBy !== "considered_gstin"
    ) {
      updatedFilterTypes = [
        { label: "Equals", value: "equals" },
        { label: "Greater then", value: "greater_then" },
        { label: "Less then", value: "less_then" },
      ];
      setFilterTypeOptions(updatedFilterTypes);
    } else {
      setFilterTypeOptions([
        { label: "Contains", value: "contains" },
        { label: "Equals", value: "equals" },
      ]);
    }
  }, [activeFilterBy]);

  const downloadBinaryFile = (binaryData: any) => {
    const blob = new Blob([binaryData]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv");
    link.click();
  };
  const downloadExcess2BReport = () => {
    setIsFullScreenLoading(true);
    axios
      .post("/api/download-report", {
        active_filters: JSON.stringify("activeMappedFilters"),
        page: 1,
        fy: "activeExcess2bFy",
        reportType: "excess2b",
      })
      .then((response) => {
        setIsFullScreenLoading(false);
        downloadBinaryFile(response.data);
      })
      .catch((error) => console.error(error));
  };

  const getReportExcess2B = (activeExcess2bTab:string) => {
    if (!activeFY) {
      toast.error("Please Select Financial year");
      return;
    }
    console.log("--- ---")
    console.log(activeExcess2bTab)
    console.log("--- ---")
    if (activeExcess2bTab === "gstin_wise") {
      setFilterByOptions([
        { label: "Considered GSTIN", value: "considered_gstin" },
        { label: "CGST 2B", value: "cgst_2b" },
        { label: "SGST 2B", value: "sgst_2b" },
        { label: "IGST 2B", value: "igst_2b" },
        { label: "CGST pr", value: "cgst_pr" },
        { label: "SGST pr", value: "sgst_pr" },
        { label: "IGST pr", value: "igst_pr" },
        { label: "CGST Excess", value: "cgst_excess" },
        { label: "SGST Excess", value: "sgst_excess" },
        { label: "IGST Excess", value: "igst_excess" },
      ]);
    } else if (activeExcess2bTab === "invoice_wise") {
      setFilterByOptions([
        { label: "Category", value: "category" },
        { label: "Supplier GSTIN", value: "supplier_gstin" },
        { label: "Considered GSTIN", value: "considered_gstin" },
        { label: "Doc Date", value: "doc_date" },
        { label: "Total GST", value: "total_gst" },
        { label: "Doc No", value: "doc_no" },
      ]);
    }
    let data = {
      filter_on: "",
      query: "",
      filter_type: "",
      fy: activeFY,
      excess2b_type: activeExcess2bTab,
    };
    if (activeFilterBy && activeFilterQuery && activeFilterQuery) {
      data = {
        filter_on: activeFilterBy,
        query: activeFilterQuery,
        filter_type: activeFilterType,
        fy: activeFY,
        excess2b_type: activeExcess2bTab,
      };
    }
    setIsLoading(true);
    axios
      .post("/api/get-report-excess2b", data)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (activeExcess2bTab === "_wise") {
          setFilterByOptions([
            { label: "Considered GSTIN", value: "considered_gstin" },
            { label: "CGST 2B", value: "cgst_2b" },
            { label: "SGST 2B", value: "sgst_2b" },
            { label: "IGST 2B", value: "igst_2b" },
            { label: "CGST pr", value: "cgst_pr" },
            { label: "SGST pr", value: "sgst_pr" },
            { label: "IGST pr", value: "igst_pr" },
            { label: "CGST Excess", value: "cgst_excess" },
            { label: "SGST Excess", value: "sgst_excess" },
            { label: "IGST Excess", value: "igst_excess" },
          ]);
        } else if (activeExcess2bTab === "invoice_wise") {
          setFilterByOptions([
            { label: "Category", value: "category" },
            { label: "Supplier GSTIN", value: "supplier_gstin" },
            { label: "Considered GSTIN", value: "considered_gstin" },
            { label: "Doc Date", value: "doc_date" },
            { label: "Total GST", value: "total_gst" },
            { label: "Doc No", value: "doc_no" },
          ]);
        }
        if (activeExcess2bTab == "gstin_wise") {
          if (response.data.data.length > 0) {
            setIsReportFetched(true);
            dispatch(
              pushGstinWiseData({
                data: response.data.data,
                totalPages: response.data.total_count,
                totalInvoiceCount: response.data.total_count,
                CGSTSum: response.data.cgst_sum,
                IGSTSum: response.data.igst_sum,
                SGSTSum: response.data.sgst_sum,
                totalGSTSum: response.data.total_gst_sum,
                taxableValueSum: response.data.taxable_value_sum,
              })
            );
            if (activeFilterBy && activeFilterQuery && activeFilterQuery) {
              dispatch(addGstinWiseFilters(data));
            } else {
              dispatch(updateActiveGstinWiseFY({ fy: activeFY }));
            }
            return;
          } else {
            toast("No Data Found");
            return;
          }
        } else {
          if (response.data.data.length > 0) {
            setIsReportFetched(true);
            dispatch(
              pushInvoiceWiseData({
                data: response.data.data,
                totalPages: response.data.total_count,
                totalInvoiceCount: response.data.total_count,
                CGSTSum: response.data.cgst_sum,
                IGSTSum: response.data.igst_sum,
                SGSTSum: response.data.sgst_sum,
                totalGSTSum: response.data.total_gst_sum,
                taxableValueSum: response.data.taxable_value_sum,
              })
            );
            if (activeFilterBy && activeFilterQuery && activeFilterQuery) {
              dispatch(addInvoiceWiseFilters(data));
            } else {
              dispatch(updateActiveInvoiceWiseFY({ fy: activeFY }));
            }

            return;
          } else {
            toast("No Data Found");
            return;
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Server side error occurred");
      });
  };
  return (
    <>
      <div className="layout-content">
        <Card style={{ padding: 0, minHeight: "85vh" }}>
          {fetchedGstinWiseReport.length > 0 ||
          fetchedInvoiceWiseReport.length > 0 ? (
            // true ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "20%",
                }}
              >
                <h2 style={{ fontWeight: 600, margin: "0 0 5px 0" }}>
                  Excess 2b Records{" "}
                </h2>{" "}
                <Button
                  onClick={(e) => {
                    // downloadMappedReport();
                  }}
                  style={{
                    width: 50,
                    height: 25,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </div>
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 10,
                  color: "grey",
                }}
              >
                <b>Total Invoices : 0</b>{" "}
                {/* {totalInvoiceCountMapped?.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })} */}
              </div>

              <Tabs
                onChange={(key: string) => {
                  dispatch(updateActiveExcess2bTab({ activeTab: key }));
                    getReportExcess2B(key);
                }}
                activeKey={activeExcess2bTab}
              >
                <TabPane
                  tab="GSTIN Wise"
                  style={{ width: "100%" }}
                  key="gstin_wise"
                >
                  <Excess2BGstinWise activeFilterBy={activeFilterBy} activeFilterQuery={activeFilterQuery} activeFilterType={activeFilterType} setIsLoading={setIsLoading} setIsReportFetched={setIsReportFetched} />
                </TabPane>

                <TabPane tab="Invoice Wise" key="invoice_wise">
                  <Excess2BInvoiceWise activeFilterBy={activeFilterBy} activeFilterQuery={activeFilterQuery} activeFilterType={activeFilterType} setIsLoading={setIsLoading} setIsReportFetched={setIsReportFetched}  />
                </TabPane>
              </Tabs>
            </>
          ) : (
            // -------------------------------------------------------------------------------------------------------
            <>
              <h2 style={{ fontWeight: 600, margin: "0 0 5px 0" }}>
                Excess 2B{" "}
              </h2>

              <div style={{ width: "100%" }}>
                <Select
                  style={{ width: 320 }}
                  size="large"
                  onChange={(e) => {
                    setActiveFY(e);
                  }}
                  placeholder="Select financial year *"
                  options={[
                    { value: "2020_21", label: "FY: 2020-21" },
                    { value: "2021_22", label: "FY: 2021-22" },
                    { value: "2022_23", label: "FY: 2022-23" },
                    { value: "2023_24", label: "FY: 2023-24" },
                    { value: "2024_25", label: "FY: 2024-25" },
                  ]}
                />
                <Select
                  style={{ width: 320, marginLeft: 20 }}
                  size="large"
                  onChange={(e) => {
                    dispatch(updateActiveExcess2bTab({ activeTab: e }));
                  }}
                  placeholder="Invoice Wise/GSTIN Wise"
                  options={[
                    { value: "invoice_wise", label: "Invoice Wise" },
                    { value: "gstin_wise", label: "GSTIN Wise" },
                  ]}
                />
                <h3 style={{ fontWeight: 500, margin: "30px 0 10px 0" }}>
                  Filters (Optional)
                </h3>
                <Row>
                  <Col span={8}>
                    <Select
                      style={{ width: 320, marginTop: 0 }}
                      size="large"
                      onChange={(e) => {
                        setActiveFilterBy(e);
                      }}
                      placeholder="Filter by"
                      options={filterByOptions}
                      showSearch
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      value={activeFilterType}
                      style={{ width: 320, marginTop: 0 }}
                      size="large"
                      onChange={(e) => {
                        setActiveFilterType(e);
                      }}
                      options={filterTypeOptions}
                      placeholder={
                        activeFilterType === "" ? "Select Filter Type" : ""
                      }
                    />
                  </Col>
                  <Col span={8}>
                    {activeFilterBy === "doc_date" ||
                    activeFilterBy === "2b_invoicedate" ? (
                      <DatePicker
                        onChange={(e: any) => {
                          try {
                            setActiveFilterQuery(e.format("YYYY-MM-DD"));
                          } catch (e) {}
                        }}
                        format="YYYY-MM-DD"
                        picker="date"
                        showTime={false}
                        size="large"
                      />
                    ) : (
                      <Input
                        placeholder="Filter query"
                        value={activeFilterQuery}
                        onChange={(e) => setActiveFilterQuery(e.target.value)}
                        size="large"
                      />
                    )}
                  </Col>
                </Row>

                <Button
                  style={{ marginTop: "50px", color: "white" }}
                  onClick={(e) => {
                    getReportExcess2B(activeExcess2bTab);
                  }}
                  className="bob_color"
                >
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ color: "white" }} />}
                    />
                  ) : (
                    "Get Report"
                  )}
                </Button>
              </div>
            </>
          )}
        </Card>
      </div>
      {isFullScreenLoading ? (
        <StyledLoader>
          <Spin
            indicator={<LoadingOutlined style={{ color: "#F4811F" }} />}
            size="large"
          />
        </StyledLoader>
      ) : (
        <></>
      )}
    </>
  );
}

export default Excess2BPage;
