export const columnDefs2BReport = [
  { field: 'rin', headerName: 'RIN' },
  { field: 'bank_non_bank', headerName: 'Bank/Non-Bank' },
  { field: 'category', headerName: 'Category' },
  { field: 'business_gstin', headerName: 'Business GSTIN' },
  { field: 'bank_state_code', headerName: 'Bank State Code' },
  { field: 'tax_period', headerName: 'Tax Period' },
  { field: 'doc_type', headerName: 'Document Type' },
  { field: 'purchase_type', headerName: 'Purchase Type' },
  { field: 'doc_no', headerName: 'Document Number' },
  { field: 'doc_date', headerName: 'Document Date' },
  { field: 'pan', headerName: 'PAN' },
  { field: 'supplier_gstin', headerName: 'Supplier GSTIN' },
  { field: 'considered_gstin', headerName: 'Considered GSTIN' },
  { field: 'supplier_name', headerName: 'Supplier Name' },
  { field: 'supplier_state', headerName: 'Supplier State' },
  { field: 'place_of_supply', headerName: 'Place of Supply' },
  { field: 'bill_of_entry_date', headerName: 'Bill of Entry Date' },
  { field: 'bill_of_entry_no', headerName: 'Bill of Entry Number' },
  { field: 'port_code', headerName: 'Port Code' },
  { field: 'reference_doc_no', headerName: 'Reference Document Number' },
  { field: 'reference_doc_date', headerName: 'Reference Document Date' },
  { field: 'reverse_charge', headerName: 'Reverse Charge' },
  { field: 'doc_value', headerName: 'Document Value' },
  { field: 'item_taxable_value', headerName: 'Item Taxable Value' },
  { field: 'gst_rate', headerName: 'GST Rate' },
  { field: 'igst', headerName: 'IGST' },
  { field: 'cgst', headerName: 'CGST' },
  { field: 'sgst', headerName: 'SGST' },
  { field: 'cess', headerName: 'CESS' },
  { field: 'total_gst', headerName: 'Total GST' },
  { field: 'is_amendment', headerName: 'Is Amendment' },
  { field: 'amendment_tax_period', headerName: 'Amendment Tax Period' },
  { field: 'original_revised_doc_no', headerName: 'Original/Revised Document Number' },
  { field: 'original_revised_doc_date', headerName: 'Original/Revised Document Date' },
  { field: 'gstr1_status', headerName: 'GSTR1 Status' },
  { field: 'gstr1_filing_period', headerName: 'GSTR1 Filing Period' },
  { field: 'gstr1_filing_date', headerName: 'GSTR1 Filing Date' },
  { field: 'itc_eligible', headerName: 'ITC Eligible' },
  { field: 'itc_ineligible_reason', headerName: 'ITC Ineligible Reason' },
  { field: 'icegate_reference_date', headerName: 'Icegate Reference Date' },
  { field: 'icegate_received_date', headerName: 'Icegate Received Date' },
  { field: 'gstr3b_status', headerName: 'GSTR3B Status' },
  { field: 'cancellation_date', headerName: 'Cancellation Date' },
  { field: 'uploaded_by', headerName: 'Uploaded By' },
  { field: 'portal_status', headerName: 'Portal Status' },
  { field: 'source', headerName: 'Source' },
  { field: 'irn_date', headerName: 'IRN Date' },
  { field: 'irn', headerName: 'IRN' },
  { field: 'reco_action', headerName: 'Reconciliation Action' },
  { field: 'reco_notes', headerName: 'Reconciliation Notes' },
  { field: 'remarks', headerName: 'Remarks' }
];

export const columnDefsPRReport = [
  { headerName: 'RIN', field: 'rin' },
  { headerName: 'Category', field: 'category' },
  { headerName: 'Purchase Month', field: 'purchase_month' },
  { headerName: 'Purchase Month Date', field: 'purchase_month_date' },
  { headerName: 'Vendor Name', field: 'vendor_name' },
  { headerName: 'Supplier Name', field: 'suppliar_name' },
  { headerName: 'Vendor GSTIN', field: 'corrected_gstin' },
  { headerName: 'Considered GSTIN', field: 'considered_gstin' },
  { headerName: 'PAN', field: 'pan' },
  { headerName: 'GSTIN Status', field: 'gstin_status' },
  { headerName: 'Len Row', field: 'len_row' },
  { headerName: 'Vendor State Code', field: 'vendor_state_code' },
  { headerName: 'Branch Sol ID', field: 'branch_sol_id' },
  { headerName: 'Bank State Code', field: 'bank_state_code' },
  { headerName: 'Bank GST', field: 'bank_gst' },
  { headerName: 'HSN', field: 'hsn' },
  { headerName: 'Nature of Service', field: 'nature_of_service' },
  { headerName: 'PL Code', field: 'pl_code' },
  { headerName: 'PL Description', field: 'pl_description' },
  { headerName: 'Invoice Date', field: 'invoice_date' },
  { headerName: 'New Date of Invoice', field: 'new_date_of_invoice' },
  { headerName: 'Categ Code', field: 'categ_code' },
  { headerName: 'Invoice No.', field: 'invoice_no' },
  { headerName: 'Invoice Amount', field: 'invoice_amount' },
  { headerName: 'CGST', field: 'cgst' },
  { headerName: 'SGST', field: 'sgst' },
  { headerName: 'IGST', field: 'igst' },
  { headerName: 'TDS', field: 'tds' },
  { headerName: 'TDS Sec', field: 'tds_sec' },
  { headerName: 'Total Amount', field: 'total_amount' },
  { headerName: 'Tran ID', field: 'tran_id' },
  { headerName: 'Venpay ID', field: 'venpay_id' },
  { headerName: 'SAC', field: 'sac' },
  { headerName: 'SAC Cost', field: 'sac_cost' },
  { headerName: 'Efficient Transfer T Amount', field: 'eff_trf_tamt' },
  { headerName: 'Transfer Flag', field: 'trf_flag' },
  { headerName: 'Transaction Date', field: 'tran_date' },
  { headerName: 'Purchase Date', field: 'purchase_date' },
  { headerName: 'Is Ineligible (Others Cenvat)', field: 'is_ineligible_others_cenvat' },
  { headerName: 'Total GST', field: 'total_gst' },
  { headerName: 'Taxable Value', field: 'taxable_value' },
  { headerName: 'Month', field: 'month' },
  { headerName: 'Remarks', field: 'remarks' },
  { headerName: 'GST Rate', field: 'gst_rate' }
]


export const columnDefMappedRecord=[

    {
      headerName: "PR",
      headerClass: "header-center color-dark",
      cellStyle: { textAlign: "center" },
      field: "pr",
      children: [
        { headerName: "Rin", field: "g_rin",headerClass:"color-dark" },
        { headerName: "Vendor GSTIN", field: "books_vendor_gstin",headerClass:"color-dark" },
        { headerName: "Category", field: "category" ,headerClass:"color-dark"},
        { headerName: "Invoice no", field: "books_invoice_no",headerClass:"color-dark" },
        { headerName: "Invoice date", field: "books_invoice_date",headerClass:"color-dark" },
        { headerName: "Total GST", field: "books_total_gst",headerClass:"color-dark" },
      ],
    },

    {
      headerName: "2B",
      headerClass: "header-center",
      field: "2b",
      children: [
        { headerName: "Rin", field: "r_rin" },
        { headerName: "Vendor GSTIN", field: "ret_vendor_gstin" },
        { headerName: "Invoice no", field: "ret_invoice_no" },
        { headerName: "Invoice date", field: "ret_invoice_date" },
        { headerName: "Total GST", field: "ret_total_gst" },
      ],
    }

]

export const columnDefGstinWise=[

    { headerName: 'Considered GSTIN', field: 'considered_gstin' },
    { headerName: 'CGST 2B', field: 'cgst_2b' },
    { headerName: 'SGST 2B', field: 'sgst_2b' },
    { headerName: 'IGST 2B', field: 'igst_2b' },
    { headerName: 'CGST PR', field: 'cgst_pr' },
    { headerName: 'SGST PR', field: 'sgst_pr' },
    { headerName: 'IGST PR', field: 'igst_pr' },
    { headerName: 'CGST Excess', field: 'cgst_excess' },
    { headerName: 'SGST Excess', field: 'sgst_excess' },
    { headerName: 'IGST Excess', field: 'igst_excess' }
  
  
]

export const columnDefInvoiceWise=[
  { headerName: 'RIN', field: 'rin' },
  { headerName: 'Category', field: 'category' },
  { headerName: 'Business GSTIN', field: 'business_gstin' },
  { headerName: 'Bank State Code', field: 'bank_state_code' },
  { headerName: 'Tax Period', field: 'tax_period', type: 'date' },
  { headerName: 'Doc Type', field: 'doc_type' },
  // { headerName: 'Purchase Type', field: 'purchase_type' }, // commented out
  { headerName: 'Doc No', field: 'doc_no' },
  { headerName: 'Doc Date', field: 'doc_date', type: 'date' },
  { headerName: 'Supplier GSTIN', field: 'supplier_gstin' },
  { headerName: 'Supplier Name', field: 'supplier_name' },
  { headerName: 'Supplier State', field: 'supplier_state' },
  { headerName: 'Place of Supply', field: 'place_of_supply' },
  { headerName: 'Reverse Charge', field: 'reverse_charge' },
  { headerName: 'Doc Value', field: 'doc_value', type: 'numericColumn' },
  { headerName: 'GST Rate', field: 'gst_rate', type: 'numericColumn' },
  { headerName: 'IGST', field: 'igst', type: 'numericColumn' },
  { headerName: 'CGST', field: 'cgst', type: 'numericColumn' },
  { headerName: 'SGST', field: 'sgst', type: 'numericColumn' },
  { headerName: 'Total GST', field: 'total_gst', type: 'numericColumn' },
  { headerName: 'Is Amendment', field: 'is_amendment' },
  { headerName: 'Original/Revised Doc No', field: 'original_revised_doc_no' },
  { headerName: 'Original/Revised Doc Date', field: 'original_revised_doc_date', type: 'date' },
  { headerName: 'ITC Eligible', field: 'itc_eligible' },
  { headerName: 'ITC Ineligible Reason', field: 'itc_ineligible_reason' },
  { headerName: 'Remarks', field: 'remarks' }
];
