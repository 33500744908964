import Input from "antd/es/input/Input";

export const approvalDataInitialState=[
    {
      particulars: "Taxable Value"
    },
    {
      particulars: "GST"
    },
    {
      particulars: "Others"
    },
    {
      particulars: "Invoice Value"
    },
    {
      particulars: "TDS"
    },
    {
      particulars: "GST TDS"
    },
    {
      particulars: "Net Payable Amount"
    },
  ]

  export const defaultColDefProps = {
    sortable: true,
    filter: false,
  }