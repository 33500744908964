/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef, useMemo, useCallback } from "react";

import {
    Card,
    Tag,
    Button,
    Radio,
    Pagination,
    Select,
    Input,
    DatePicker,
    Tabs
} from "antd";

import { DownloadOutlined } from '@ant-design/icons';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS

const { TabPane } = Tabs;
const { Option } = Select;
const columnDefs2BReport = [
    { field: 'rin', headerName: 'RIN' },
  { field: 'category', headerName: 'Category' },
  { field: 'business_gstin', headerName: 'Business GSTIN' },
  { field: 'bank_state_code', headerName: 'Bank State Code' },
  { field: 'tax_period', headerName: 'Tax Period' },
  { field: 'doc_type', headerName: 'Document Type' },
  { field: 'purchase_type', headerName: 'Purchase Type' },
  { field: 'doc_no', headerName: 'Document Number' },
  { field: 'doc_date', headerName: 'Document Date' },
  { field: 'pan', headerName: 'PAN' },
  { field: 'supplier_gstin', headerName: 'Supplier GSTIN' },
  { field: 'considered_gstin', headerName: 'Considered GSTIN' },
  { field: 'supplier_name', headerName: 'Supplier Name' },
  { field: 'supplier_state', headerName: 'Supplier State' },
  { field: 'place_of_supply', headerName: 'Place of Supply' },
  { field: 'bill_of_entry_date', headerName: 'Bill of Entry Date' },
  { field: 'bill_of_entry_no', headerName: 'Bill of Entry Number' },
  { field: 'port_code', headerName: 'Port Code' },
  { field: 'reference_doc_no', headerName: 'Reference Document Number' },
  { field: 'reference_doc_date', headerName: 'Reference Document Date' },
  { field: 'reverse_charge', headerName: 'Reverse Charge' },
  { field: 'doc_value', headerName: 'Document Value' },
  { field: 'item_taxable_value', headerName: 'Item Taxable Value' },
  { field: 'gst_rate', headerName: 'GST Rate' },
  { field: 'igst', headerName: 'IGST' },
  { field: 'cgst', headerName: 'CGST' },
  { field: 'sgst', headerName: 'SGST' },
  { field: 'cess', headerName: 'CESS' },
  { field: 'total_gst', headerName: 'Total GST' },
  { field: 'is_amendment', headerName: 'Is Amendment' },
  { field: 'amendment_tax_period', headerName: 'Amendment Tax Period' },
  { field: 'original_revised_doc_no', headerName: 'Original/Revised Document Number' },
  { field: 'original_revised_doc_date', headerName: 'Original/Revised Document Date' },
  { field: 'gstr1_status', headerName: 'GSTR1 Status' },
  { field: 'gstr1_filing_period', headerName: 'GSTR1 Filing Period' },
  { field: 'gstr1_filing_date', headerName: 'GSTR1 Filing Date' },
  { field: 'itc_eligible', headerName: 'ITC Eligible' },
  { field: 'itc_ineligible_reason', headerName: 'ITC Ineligible Reason' },
  { field: 'icegate_reference_date', headerName: 'Icegate Reference Date' },
  { field: 'icegate_received_date', headerName: 'Icegate Received Date' },
  { field: 'gstr3b_status', headerName: 'GSTR3B Status' },
  { field: 'cancellation_date', headerName: 'Cancellation Date' },
  { field: 'uploaded_by', headerName: 'Uploaded By' },
  { field: 'portal_status', headerName: 'Portal Status' },
  { field: 'source', headerName: 'Source' },
  { field: 'irn_date', headerName: 'IRN Date' },
  { field: 'irn', headerName: 'IRN' },
  { field: 'reco_action', headerName: 'Reconciliation Action' },
  { field: 'reco_notes', headerName: 'Reconciliation Notes' },
  { field: 'remarks', headerName: 'Remarks' }
];

const columnDefsPRReport = [
    {headerName: 'RIN', field: 'rin'},
    {headerName: 'Category', field: 'category'},
    {headerName: 'Purchase Month', field: 'purchase_month'},
    {headerName: 'Purchase Month Date', field: 'purchase_month_date'},
    {headerName: 'Vendor Name', field: 'vendor_name'},
    {headerName: 'Supplier Name', field: 'suppliar_name'},
    {headerName: 'Vendor GSTIN', field: 'vendor_gstin'},
    {headerName: 'Considered GSTIN', field: 'considered_gstin'},
    {headerName: 'PAN', field: 'pan'},
    {headerName: 'GSTIN Status', field: 'gstin_status'},
    {headerName: 'Len Row', field: 'len_row'},
    {headerName: 'Vendor State Code', field: 'vendor_state_code'},
    {headerName: 'Branch Sol ID', field: 'branch_sol_id'},
    {headerName: 'Bank State Code', field: 'bank_state_code'},
    {headerName: 'Bank GST', field: 'bank_gst'},
    {headerName: 'HSN', field: 'hsn'},
    {headerName: 'Nature of Service', field: 'nature_of_service'},
    {headerName: 'PL Code', field: 'pl_code'},
    {headerName: 'PL Description', field: 'pl_description'},
    {headerName: 'Invoice Date', field: 'invoice_date'},
    {headerName: 'New Date of Invoice', field: 'new_date_of_invoice'},
    {headerName: 'Categ Code', field: 'categ_code'},
    {headerName: 'Invoice No.', field: 'invoice_no'},
    {headerName: 'Invoice Amount', field: 'invoice_amount'},
    {headerName: 'CGST', field: 'cgst'},
    {headerName: 'SGST', field: 'sgst'},
    {headerName: 'IGST', field: 'igst'},
    {headerName: 'TDS', field: 'tds'},
    {headerName: 'TDS Sec', field: 'tds_sec'},
    {headerName: 'Total Amount', field: 'total_amount'},
    {headerName: 'Tran ID', field: 'tran_id'},
    {headerName: 'Venpay ID', field: 'venpay_id'},
    {headerName: 'SAC', field: 'sac'},
    {headerName: 'SAC Cost', field: 'sac_cost'},
    {headerName: 'Efficient Transfer T Amount', field: 'eff_trf_tamt'},
    {headerName: 'Transfer Flag', field: 'trf_flag'},
    {headerName: 'Transaction Date', field: 'tran_date'},
    {headerName: 'Purchase Date', field: 'purchase_date'},
    {headerName: 'Is Ineligible (Others Cenvat)', field: 'is_ineligible_others_cenvat'},
    {headerName: 'Total GST', field: 'total_gst'},
    {headerName: 'Taxable Value', field: 'taxable_value'},
    {headerName: 'Month', field: 'month'},
    {headerName: 'Remarks', field: 'remarks'},
    {headerName: 'GST Rate', field: 'gst_rate'}
  ]
  

function ReportsPage() {
    const gridRef = useRef<AgGridReact>(null); // Optional - for accessing Grid's API
    const pageSize = 100; // number of items per page
    const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
    const [columnDefs, setColumnDefs] = useState(columnDefsPRReport);
    const [downloadStatus, setDownloadStatus] = useState("Download");
    const [filterOptions, setFilterOptions] = useState(
        [
            { option: 'Category', value: 'match_category' },
            { option: 'Vendor gstin', value: 'vendor_gstin' },
            { option: 'Invoice Date', value: 'invoice_date' },
            { option: 'Total GST', value: 'total_gst' },
            { option: 'Bank state code', value: 'bank_state_code' },
            { option: 'Invoice No', value: 'invoice_number' },
            { option: 'Pan', value: 'pan' },
        ]
    )
    const [filterTypeOptions, setFilterTypeOptions] = useState(
        [
            { option: 'Contains', value: 'contains' },
            { option: 'Equals', value: 'equals' },
        ]
    )
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('pr');
    const [totalCount, setTotalCount] = useState(14000);
    const [searchSuccessful, setSearchSuccessful] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterOn, setFilterOn] = useState('match_category')
    const [filterType, setFilterType] = useState('contains')
    const [activeFilter, setActiveFilter] = useState([])
    const [activeFY, setActiveFY] = useState('2020_21')
    const [gridApi, setGridApi] = useState<any>();
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
        let data = { page: page, fy: activeFY }
        switch (activeTab) {
            case '2b':
                setColumnDefs(columnDefs2BReport)
                setActiveTab('2b')
                if (activeFilter.length > 0) {
                    const data = {
                        report_type: activeTab,
                        filter_on: filterOn,
                        query: searchText,
                        filter_type: filterType,
                        fy: activeFY
                    };
                    let allFilters: any = []
                    activeFilter.forEach(filter => {
                        allFilters.push(filter)
                    })

                    let addNew = true
                    allFilters.forEach((filter: any, index: number) => {
                        if (data.filter_on === filter['filter_on']) {
                            allFilters.splice(index, 1)
                        }
                    })
                    if (addNew) {
                        allFilters.push(data)
                    }
                    setActiveFilter(allFilters)
                    axios.post('/api/filter-report', { active_filters: JSON.stringify(allFilters), page: page, fy: activeFY })
                        .then(response => {
                            setRowData(response.data.data);
                            setSearchSuccessful(true)
                            setTotalCount(response.data.total_count)
                        })
                        .catch(error => console.error(error));
                } else {
                    axios.post('/api/get-report-2b', data)
                        .then(response => {
                            // handle the response data here
                            console.log(response.data);
                            setRowData(response.data.data)
                        })
                        .catch(error => {
                            // handle any errors here
                            console.error(error);
                        });
                }
                break;
            case 'pr':
                setActiveTab('pr')
                if (activeFilter.length > 0) {
                    const data = {
                        report_type: activeTab,
                        filter_on: filterOn,
                        query: searchText,
                        filter_type: filterType,
                        fy: activeFY
                    };
                    let allFilters: any = []
                    activeFilter.forEach(filter => {
                        allFilters.push(filter)
                    })

                    let addNew = true
                    allFilters.forEach((filter: any, index: number) => {
                        if (data.filter_on === filter['filter_on']) {
                            allFilters.splice(index, 1)
                        }
                    })
                    if (addNew) {

                        allFilters.push(data)
                    }

                    console.log(allFilters, allFilters.indexOf({ ...data }))
                    setRowData([]);
                    axios.post('/api/filter-report', { active_filters: JSON.stringify(allFilters), page: page, fy: activeFY })
                        .then(response => {
                            setRowData(response.data.data);
                            setSearchSuccessful(true)
                            setTotalCount(response.data.total_count)
                        })
                        .catch(error => console.error(error));
                } else {

                    axios.post('/api/get-report-pr', data)
                        .then(response => {
                            // handle the response data here
                            console.log(response.data);
                            setRowData(response.data.data)
                        })
                        .catch(error => {
                            // handle any errors here
                            console.error(error);
                        });
                }
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        axios.post('/api/get-report-pr')
            .then(response => {
                // handle the response data here
                console.log(response.data);
                setRowData(response.data.data)
                setTotalCount(response.data.total_count)
            })
            .catch(error => {
                // handle any errors here
                console.error(error);
            });

    }, []);
    // Example of consuming Grid Event
    const cellClickedListener = useCallback((event: any) => {
        console.log('cellClicked', event);
    }, []);
    // DefaultColDef sets props common to all Columns

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: false,
        // Default Column Properties

    }), []);
    const switchTab = (e: any) => {
        console.log(e.target.value)
        setActiveFilter([])
        let data = { page: currentPage, fy: activeFY }
        let updatedFilterOption: any
        switch (e.target.value) {
            case '2b':
                updatedFilterOption = [
                    { option: 'Category', value: 'match_category' },
                    { option: 'Supplier gstin', value: 'supplier_gstin' },
                    { option: 'Considered gstin', value: 'considered_gstin' },
                    { option: 'Business gstin', value: 'business_gstin' },
                    { option: 'Invoice Date', value: 'invoice_date' },
                    { option: 'Total GST', value: 'total_gst' },
                    { option: 'Bank state code', value: 'bank_state_code' },
                    { option: 'Bill of entry no', value: 'bill_of_entry_no' },
                ]
                setFilterOptions(updatedFilterOption)
                setColumnDefs(columnDefs2BReport)
                setActiveTab('2b')
                setCurrentPage(1)
                axios.post('/api/get-report-2b', data)
                    .then(response => {
                        // handle the response data here
                        console.log(response.data);
                        setRowData(response.data.data)
                        setTotalCount(response.data.total_count)
                    })
                    .catch(error => {
                        // handle any errors here
                        console.error(error);
                    });
                break;
            case 'pr':
                updatedFilterOption =
                    [
                        { option: 'Category', value: 'match_category' },
                        { option: 'Vendor gstin', value: 'vendor_gstin' },
                        { option: 'Invoice Date', value: 'invoice_date' },
                        { option: 'Total GST', value: 'total_gst' },
                        { option: 'Bank state code', value: 'bank_state_code' },
                        { option: 'Invoice No', value: 'invoice_number' },
                        { option: 'Pan', value: 'pan' },
                    ]

                setFilterOptions(updatedFilterOption)
                setColumnDefs(columnDefsPRReport)
                setActiveTab('pr')
                axios.post('/api/get-report-pr', data)
                    .then(response => {
                        // handle the response data here
                        console.log(response.data);
                        setRowData(response.data.data)
                        setTotalCount(response.data.total_count)
                    })
                    .catch(error => {
                        // handle any errors here
                        console.error(error);
                    });
                break;
            default:
                break;
        }
    }


    const handleFilterClick = () => {
        const data = {
            report_type: activeTab,
            filter_on: filterOn,
            query: searchText,
            filter_type: filterType,
            fy: activeFY
        };
        let allFilters: any = []
        let addNew = true
        activeFilter.forEach(filter => {
            allFilters.push(filter)
        })
        allFilters.forEach((filter: any, index: number) => {
            if (data.filter_on === filter['filter_on']) {
                allFilters.splice(index, 1)
            }
        })
        if (addNew) {
            allFilters.push(data)
        }
        setActiveFilter(allFilters)
        console.log(allFilters, allFilters.indexOf({ ...data }))
        gridApi.showLoadingOverlay();
        axios.post('/api/filter-report', { active_filters: JSON.stringify(allFilters), page: 1 ,fy:activeFY})
            .then(response => {
                gridApi.hideOverlay();
                setRowData(response.data.data);
                setSearchSuccessful(true)
                setTotalCount(response.data.total_count)
            })
            .catch(error => {
                console.error(error)
                gridApi.hideOverlay();

            });

    };

    const downloadBinaryFile = (binaryData: any) => {
        const blob = new Blob([binaryData]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv');
        link.click();
    }

    const handleDownloadClick = () => {
        const data = {
            report_type: activeTab,
            filter_on: filterOn,
            query: searchText,
            fy: activeFY

        };
        setDownloadStatus("Please wait..");
        axios.post('/api/download-report', { active_filters: JSON.stringify(activeFilter), page: 1,fy:activeFY })
            .then(response => {
                downloadBinaryFile(response.data)
                setSearchSuccessful(true)
                setDownloadStatus("Download started");
                setTimeout(() => {
                    setDownloadStatus("Download");
                    
                }, 2000);
            })
            .catch(error => console.error(error));
    };
    const onGridReady = (params: any) => {
        setGridApi(params.api);
    };
    return (
        <>
            <div className="layout-content">
                <Card style={{padding:0}}>
                    <div style={{ display: "flex", justifyContent: "flex-start",padding:0 }}>
                        <h4 style={{ margin: 0, color: "grey", display: activeFilter.length === 0 ? "none" : '' }}>Active filters: </h4>
                        {activeFilter.map((filter: any, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={(e: React.MouseEvent<HTMLElement>) => {
                                    e.preventDefault();
                                    console.log(activeFilter)
                                    let filterToRemove = activeFilter[index];
                                    let newFilter: any = []
                                    activeFilter.forEach(filter_2 => {
                                        if (filter_2["filter_on"] !== filterToRemove["filter_on"]) {
                                            newFilter.push(filter_2)
                                        }
                                    })
                                    setActiveFilter(newFilter)
                                    gridApi.showLoadingOverlay();
                                    axios.post('/api/filter-report', { active_filters: JSON.stringify(newFilter), page: 1, report_type: activeTab,fy:activeFY })
                                        .then(response => {
                                            setRowData(response.data.data);
                                            setTotalCount(response.data.total_count)
                                            gridApi.hideOverlay();
                                        })
                                        .catch(error => {
                                            console.error(error)
                                            gridApi.hideOverlay();
                                        });
                                    console.log(activeFilter)
                                }}
                                color="green"
                                style={{ marginLeft: 10 }}
                            >
                                {filter.filter_on}
                            </Tag>
                        ))}

                    </div>
                    {/* <Option key={option.value} value={option.value}>
                                        {option.option || option.value}
                                    </Option> */}
                    <div style={{ display: 'flex', float: "right" }}>


                        <Select  showSearch placeholder={"Please select"} size="large" onChange={(e: any) => {
                            setFilterOn(e)
                            let updatedFilterTypes = []
                            switch (e) {
                                case "match_category":
                                case "considered_gstin":
                                case "supplier_gstin":
                                case "business_gstin":
                                case "bank_state_code":
                                case "invoice_number":
                                case "vendor_gstin":
                                case "pan":
                                    updatedFilterTypes = [{ option: 'Contains', value: 'contains' }, { option: 'Equals', value: 'equals' }]
                                    setFilterTypeOptions(updatedFilterTypes)
                                    break;

                                case "total_gst":
                                    updatedFilterTypes = [{ option: 'Equals', value: 'equals' }, { option: 'Greater then', value: 'greater_then' }, { option: 'Less then', value: 'less_then' }]
                                    setFilterTypeOptions(updatedFilterTypes)
                                    break;

                                case "invoice_date":
                                    updatedFilterTypes = [{ option: 'Equals', value: 'equals' }, { option: 'After', value: 'greater_then' }, { option: 'Before', value: 'less_then' }]
                                    setFilterTypeOptions(updatedFilterTypes)
                                    break;

                                default:
                                    break;
                            }
                        }} style={{ width: '150px', marginLeft: "10px" }}>
                            {filterOptions.map((option) => {

                                return (
                                    <Option key={option.value} value={option.value}>
                                        {option.option || option.value}
                                    </Option>
                                )

                            })}
                        </Select>
                        <Select  placeholder={"Please select"} size="large" onChange={(e: any) => { setFilterType(e) }} style={{ width: '150px',marginLeft: '10px' }}>

                            {filterTypeOptions.map((option) => {

                                return (
                                    <Option key={option.value} value={option.value}>
                                        {option.option || option.value}
                                    </Option>
                                )

                            })}
                        </Select>
                        {
                            filterOn === 'invoice_date' ? (
                                <DatePicker
                                    onChange={(e: any) => {
                                        try {

                                            setSearchText(e.format('YYYY-MM-DD'))
                                        } catch (e) { }
                                    }}
                                    format="YYYY-MM-DD"
                                    picker="date"
                                    showTime={false}
                                    style={{ marginLeft: 8, flex: 1 }}
                                />
                            ) : (

                                <Input
                                    placeholder="Filter query"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    style={{ marginLeft: 8, flex: 1 }}
                                />
                            )
                        }
                        <Button type="primary" onClick={handleFilterClick} style={{ marginLeft: 8 }}>
                            Filter
                        </Button>
                        {/* show download button only when search is successful */}
                        {searchSuccessful && (
                            <Button type="primary" icon={<DownloadOutlined />} onClick={handleDownloadClick} style={{ marginLeft: 8 }}>
                                {downloadStatus}
                            </Button>
                        )}
                    </div>

                    {/* <div className="ant-filtertabs">
                        <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                            <Radio.Group onChange={(e: any) => { switchTab(e) }} defaultValue="pr">
                                <Radio.Button value="pr">PR Report</Radio.Button>
                                <Radio.Button value="2b">2B Report</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div> */}

                    <Tabs tabPosition="top"   style={{marginLeft:"5px"}} onChange={e=>{
                        setActiveFY(e)


                        let data = { page: currentPage, fy: e }
                        let url:string=''
                        if(activeTab=='pr'){
                            url='/api/get-report-pr'
                        }else if(activeTab=='2b'){
                            url='/api/get-report-2b'
                        }
                        axios.post(url, data)
                        .then(response => {
                            // handle the response data here
                            console.log(response.data);
                            setRowData(response.data.data)
                            setTotalCount(response.data.total_count)
                        })
                        .catch(error => {
                            // handle any errors here
                            console.error(error);
                        });


                        }} defaultActiveKey="1" size="small">
                        <TabPane tab="FY: 2020-21" key="2020_21" />
                        
                  
                        <TabPane tab="FY: 2021-22" key="2021_22" />
                
                    

                    </Tabs>
                    <div className="ag-theme-material" style={{ width: '100%', height: "55vh" }}>

                        <AgGridReact
                            ref={gridRef} // Ref for accessing Grid's API
                            rowData={rowData} // Row Data for Rows
                            columnDefs={columnDefs} // Column Defs for Columns
                            defaultColDef={defaultColDef} // Default Column Properties
                            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                            rowSelection='multiple' // Options - allows click selection of rows
                            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                            rowHeight={25}
                            headerHeight={30}
                            onGridReady={onGridReady}
                        />

                    </div>

                    <Pagination
                        style={{ marginTop: '20px' }}
                        className="custom-pagination"
                        hideOnSinglePage
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalCount}
                        onChange={handlePageChange}
                        pageSizeOptions={[]}
                    />


                </Card>
            </div>
        </>
    );
}

export default ReportsPage;
