import React, { useEffect } from 'react';
import { ContainerOutlined, WalletOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PieChartOutlined, UserOutlined, FileDoneOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../assets/styles/sidenavcustom.css'
import { useRef } from 'react';

interface PropsInterface {
  collapseSideNav: boolean;
  toggleCollapsed: any;
}
type MenuItem = Required<MenuProps>['items'][number];



function SidenavCustom(props: PropsInterface) {
  const userRole = useSelector((state: any) => state.user.role)
  const userName = useSelector((state: any) => state.user.name)
  const sidenavRef:any = useRef(null);
  let items: MenuItem[]=[
    // {
    //   key: '1',
    //   label: <Link to="/">Dashboard</Link>,
    //   icon: <PieChartOutlined />,
    // },
    // {
    //   key: '11',
    //   label: <Link to="/vendor-followup">Vendor Follow-up</Link>,
    //   icon: <UserOutlined />,
    // },
    {
      key: 'sub1',
      label: (
        <span>

          <span>Invoice Module</span>
        </span>
      ),
      icon: <WalletOutlined />,
      children: [
        userRole==='maker' ?
        {
          key: '5',
          label: <Link to="/upload-invoice">Upload Invoice</Link>,
        }:null,
        userRole==='checker' ? 
        {
          key: '6',
          label: <Link to="/approve-invoice">Approve Invoice</Link>,
        } : null,
      
        userRole==='maker' ?
        {
          key: '7',
          label: <Link to="/invoice-reports">Invoice Reports</Link>,
        }:null,
        userRole==='checker' ?
        {
          key: '8',
          label: <Link to="/invoice-management">Invoice Management</Link>,
        }:null,
      ],
    },

    {
      key: 'subs2',
      label: (
        <span>

          <span>GST Reports and Utility</span>
        </span>
      ),
      icon: <ContainerOutlined />,
      children: [
        // {
        //   key: '9',
        //   label: <Link to="/gstr1-utility">GSTR1 Utility</Link>,
        // },

        {
          key: 'ww9',
          label: <Link to="/gstr1-utility">ITC Reconciliation Utility</Link>,
        },
        {
          key: 'wwee9',
          label: <Link to="/report/reconcillation-report">Reconcillation Report</Link>,
        },
        // {
        //   key: '13',
        //   label: <Link to="/report/3b">3B Report</Link>,
        // },
        // {
        //   key: '14',
        //   label: <Link to="/report/mapped-records">Mapped Records</Link>,
        // },
        // {
        //   key: '16',
        //   label: <Link to="/report/excess-2b">Excess 2B</Link>,
        // },
      ],
    },
    {
      key: 'sub2',
      label: (
        <span>

          <span>PR/2B Report</span>
        </span>
      ),
      icon: <ContainerOutlined />,
      children: [
      {
        key: '1d0',
        label: <Link to="/report/pr">PR Report</Link>,
      },
      {
        key: '1d2',
        label: <Link to="/report/2b">2B Report</Link>,
      },
      // {
      //   key: '1d2e',
      //   label: <Link to="/report/upload-data">Upload Data</Link>,
      // },
      
    ]
    },
    {
      key: 'sub2w',
      label: (
        <span>

          <span>GSTR Utilities</span>
        </span>
      ),
      icon: <ContainerOutlined />,
      children: [
      {
        key: '1dsdf0',
        label: <Link to="/gstr-utilities/gstr1">GSTR1</Link>,
      },
      {
        key: '1d2fsdf',
        label: <Link to="/gstr-utilities/gstr2a">GSTR 2A</Link>,
      },
    ]
    },
    {
      key: '1111',
      label: <Link to="/login" onClick={() => { axios.post("/api/logout") }}>Logout</Link>,
      icon: <LogoutOutlined />,
    }
  ];
  useEffect(() => {
    items = [
      {
        key: '1',
        label: <Link to="/">Dashboard</Link>,
        icon: <PieChartOutlined />,
      },
      {
        key: '11w',
        label: <Link to="/vendor-followup">Vendor Follow-up</Link>,
        icon: <UserOutlined />,
      },
      {
        key: 'sub1',
        label: (
          <span>
  
            <span>Invoice Module</span>
          </span>
        ),
        icon: <WalletOutlined />,
        children: [
          userRole==='maker' ?
          {
            key: '5',
            label: <Link to="/upload-invoice">Upload Invoice</Link>,
          }:null,
          userRole==='checker' ? 
          {
            key: '6',
            label: <Link to="/approve-invoice">Approve Invoice</Link>,
          } : null,
          userRole==='maker' ?
          {
            key: '7',
            label: <Link to="/invoice-reports">Invoice Reports</Link>,
          }:null,
          userRole==='checker' ?
          {
            key: '8',
            label: <Link to="/invoice-management">Invoice Management</Link>,
          }:null,
        ],
      },
      {
        key: 'subs2',
        label: (
          <span>
  
            <span>GST Reports and Utility</span>
          </span>
        ),
        icon: <ContainerOutlined />,
        children: [
          // {
          //   key: '9',
          //   label: <Link to="/gstr1-utility">GSTR1 Utility</Link>,
          // },
          {
            key: 'ww9',
            label: <Link to="/gstr1-utility">ITC Reconciliation Utility</Link>,
          },
          {
            key: 'wwee9',
            label: <Link to="/report/reconcillation-report">Reconcillation Report</Link>,
          },

          // {
          //   key: '13',
          //   label: <Link to="/report/3b">3B Report</Link>,
          // },
        ],
      },
      {
        key: 'sub2w',
        label: (
          <span>
  
            <span>GSTR Utilities</span>
          </span>
        ),
        icon: <ContainerOutlined />,
        children: [
        {
          key: '1dsdf0',
          label: <Link to="/gstr-utilities/gstr1">GSTR1</Link>,
        },
        {
          key: '1d2fsdf',
          label: <Link to="/gstr-utilities/gstr2a">GSTR 2A</Link>,
        },
      ]
      },
      {
        key: 'sub2',
        label: (
          <span>
  
            <span>PR/2B Report</span>
          </span>
        ),
        icon: <ContainerOutlined />,
        children: [
        {
          key: '1d0',
          label: <Link to="/report/pr">PR Report</Link>,
        },
        {
          key: '1d2',
          label: <Link to="/report/2b">2B Report</Link>,
        },
        // {
        //   key: '1d2e',
        //   label: <Link to="/report/upload-data">Upload Data</Link>,
        // },
      ]
      },
      {
        key: '11ww2',
        label: <Link to="/login" onClick={() => { axios.post("/api/logout") }}>Logout</Link>,
        icon: <LogoutOutlined />,
      }
    ]
console.log("Hello world")
  }, [userRole,userName])
  
  


  return (
    <>
      <Button
        type="primary"
        className="bob_color sidenavBtn"
        onClick={()=>{
          if(window.innerWidth < 576){
            if(props.collapseSideNav){
              sidenavRef.current.style.transform = 'translateX(0%)';
            }else{
              sidenavRef.current.style.transform = 'translateX(-100%)'
            }
          }
          props.toggleCollapsed()
        }}
        style={{ marginBottom: 16 }}
      >
        {props.collapseSideNav ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
    <div className='sidenavContainer' ref={sidenavRef}>

      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        inlineCollapsed={props.collapseSideNav}
        items={items.filter((item:any) => {
          // if (item.key === 'sub2') {
          //   // If username is  'kpmg', exclude the 'GST Reports and Utility' section
          //   return userName === 'Monika' || userName === 'BOB';
          // }
          // if (item.key === '10') {
          //   // If username is  'kpmg', exclude the 'GST Reports and Utility' section
          //   return userName === 'Monika' || userName === 'BOB';
          // }
          // if (item.key === '12') {
          //   // If username is  'kpmg', exclude the 'GST Reports and Utility' section
          //   return userName === 'Monika' || userName === 'BOB';
          // }    
          // // By default, include other menu items
          return true;
        })}

        style={{ height: '100vh',zIndex:999 }}
      />
    </div>
        </>
  );
}

export default SidenavCustom;
