import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoute";
import MainLayout from "./layout/MainLayout";
import HomePage from "./pages/Home";
import LogInPage from "./pages/Login";
import ProfilePage from "./pages/Profile";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddVendorPage from "./pages/AddVendor";
import ViewVendorPage from "./pages/ViewVendor";
import ReportsPage from "./pages/Reports";
import ViewInvoicePage from "./pages/InvoiceReport";
import './theme.less';
import ReportPR from "./pages/ReportPR";
import Report2b from "./pages/Report2b";
import { store } from './store'
import { Provider } from 'react-redux'
import UploadInvoice from "./pages/UploadInvoice";
import VendorFollowUp from "./pages/VendorFollowUp";
import ApproveInvoice from "./pages/ApproveInvoice";
import InvoiceManagementPage from "./pages/InvoiceManagementChecker/InvoiceManagementChecker";
import MappedRecordsPage from './pages/MappedRecords';
import Excess2BPage from "./pages/Excess2B";
import ITCReconsillationUtility from "./pages/ITCReconcillationUtility";
import ReconResult from "./pages/ReconResult";
import Download2B from "./pages/Download2B";
import ReconcillationReport from "./pages/ReconcillationReport";
import SignupPage from "./pages/Signup";
import UploadData from "./pages/UploadData";
import GSTR1 from "./pages/GSTR1";
import GSTR2A from "./pages/GSTR2A";



const router = createBrowserRouter(
  [
    {
      element: <PrivateRoutes />,
      children: [
        {
          element: <MainLayout />,
          children: [
            {
              path: "/",
              element:  <UploadInvoice />,
            },
            {
              path: "/profile",
              element: <ProfilePage />,
            },
            {
              path: "/add-vendor",
              element: <AddVendorPage />,
            },
            {
              path: "/view-vendor",
              element: <ViewVendorPage />,
            },
            {
              path: "/reports",
              element: <ReportsPage />,
            },
            {
              path: "/view-invoice",
              element: <ViewInvoicePage />,
            },
            // ----------------------------------------------------------------
            
            {
              path: "/vendor-followup",
              element: <VendorFollowUp />,
            },
            {
              path: "/invoice-reports",
              element: <ViewInvoicePage />,
            },
            {
              path: "/approve-invoice",
              element: <ApproveInvoice />,
            },
            
            {
              path: "/invoice-management",
              element: <InvoiceManagementPage/>,
            },
            {
              path: "/upload-invoice",
              element: <UploadInvoice />,
            },
            {
              path: "/gstr1-utility",
              element: <ITCReconsillationUtility />,
            },
            {
              path: "/report/pr",
              element: <ReportPR />,
            },
            {
              path: "/report/2b",
              element: <Report2b />,
            },
            {
              path: "/report/3b",
              element: <VendorFollowUp />,
            },
            
            {
              path: "/report/mapped-records",
              element: <MappedRecordsPage />,
            },
            {
              path: "/report/excess-2b",
              element: <Excess2BPage />,
            },
            {
              path: "/report/recon-result",
              element: <ReconResult />,
            },
            {
              path: "/report/download-2b",
              element: <Download2B />,
            },
            
            {
              path: "/report/reconcillation-report",
              element: <ReconcillationReport />,
            },

            {
              path: "/report/upload-data",
              element: <UploadData />,
            },
            {
              path: "/gstr/reconcillation-report",
              element: <ReconcillationReport />,
            },
            {
              path: "/gstr-utilities/gstr1",
              element: <GSTR1 />,
            },
            {
              path: "/gstr-utilities/gstr2a",
              element: <GSTR2A />,
            }
          ]
        },
      ]
    },
    
    {
      element: <LogInPage />,
      path: "/login"
    },
    {
      element: <SignupPage />,
      path: "/signup"
    }
  ]
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <Provider store={store}>
      <ToastContainer />
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  </>

);