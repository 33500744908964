import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

interface UserState {
  name: string;
  email: string;
  role: string;
}

const initialState: UserState = {
  name: "",
  email: "",
  role: ""
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setUserData: (state, action: PayloadAction<{name:string,email:string,role:string}>) => {
        state.role = action.payload.role;
        state.name = action.payload.name;
        state.email = action.payload.email;
      }
  }
});

export const { setName, setEmail, setRole, setUserData } = userSlice.actions;

export default userSlice.reducer;