import React, { useState, useRef, useMemo, useEffect } from 'react'
import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Select,
    Upload,
    Spin,
    Tooltip,
    Radio,
    Modal,
    Space,
    Form

} from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import type { UploadProps } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { template } from 'lodash';
function UploadData() {

    const { Option } = Select;
    const [invoice, setInvoice] = useState<any>()
    const [multiinvoice, setMultiInvoice] = useState<any>()
    const [invoiceType, setInvoiceType] = useState<any>()
    const [FY, setFY] = useState<any>()
    const [invoiceDate, setInvoiceDate] = useState<any>()
    const [pan, setPAN] = useState<any>()
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapTemplate, setMapTemplate] = useState<any>([]);
    const [templateType, setTemplateType] = useState<string>("PR");
    // let templatePairs = chunkArray(templateRow, 2);
    const chunkArray = (array: any, size: any) =>
        array.reduce((chunks: any, item: any, i: any) => (i % size ? chunks[chunks.length - 1].push(item) : chunks.push([item]), chunks), []);
    const { Search } = Input;
    const templateRowPR = [
        'rin', 'category', 'vendor_name',
        'corrected_gstin', 'vendor_state_code', 'branch_sol_id', 'bank_state_code',
        'bank_gstin', 'hsn', 'nature_of_service', 'pl_code', 'pl_description',
        'invoice_date', 'invoice_no', 'invoice_amount', 'cgst', 'sgst', 'igst',
        'total_amount', 'tran_id', 'venpay_id', 'tran_date',
        'ineligible_others_cenvat', 'total_gst', 'remarks'
    ]

    const templatePairPR = chunkArray(templateRowPR, 2)

    const templateRow2B = [
        'rin', 'category', 'business_gstin',
        'bank_state_code', 'tax_period', 'doc_type', 'doc_no', 'doc_date',
        'supplier_gstin', 'supplier_name', 'supplier_state', 'place_of_supply',
        'reverse_charge', 'doc_value', 'gst_rate', 'igst', 'cgst', 'sgst',
        'total_gst', 'is_amendment', 'original_revised_doc_no',
        'original_revised_doc_date', 'itc_eligible', 'itc_ineligible_reason',
        'remarks'
    ]
    const templatePair2B = chunkArray(templateRow2B, 2)
    const props_invoice: UploadProps = {
        beforeUpload(file) {
            return false;
        },
        onChange(info) {
            setInvoice(info.file);
            return
        },
    };
    const props_multi_invoice: UploadProps = {
        beforeUpload(file) {
            return false;
        },
        onChange(info) {
            setMultiInvoice(info.file);
            return

        },
    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    useEffect(() => {
        axios.post("/api/get-map-template", { type: templateType }).then((response: any) => {
            setMapTemplate([...response.data.template])
        })

    }, [templateType])

    useEffect(() => {

    }, [])


    const years = ['2020_21', '2021_22', '2022_23', '2023_24'];
    const optionsRadio = ['PR', '2B'];

    // Split the templateRow into pairs



    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        // Handle form submission
        console.log('Form values:', values);
        values["type"] = templateType
        axios.post("/api/add-template", {
            ...values
        }).then((response: any) => {
            toast.success("Template added successfully")
            axios.post("/api/get-map-template", { type: templateType }).then((response: any) => {
                setMapTemplate([...response.data.template])
            })
            setIsModalOpen(false)
        }).catch((error: any) => {
            toast.error("Error occurred while adding template")

        })
    };

    return (
        <>

            <Modal
                style={{ top: 10 }}
                title="Add Template"
                open={isModalOpen}
                onOk={handleOk}
                width={"90vw"}
                footer={null}
                onCancel={handleCancel}>
                <Form form={form} onFinish={onFinish}>

                    <Form.Item className="item" name="template_name">
                        <Input type="text" placeholder='Name of template' />
                    </Form.Item>
                    <div>

                        <h3>Map Columns</h3>
                    </div>
                    {
                        templateType === "PR" ? (
                            <>
                                {templatePairPR.map((pair: any, pairIndex: any) => (
                                    <Row key={pair[0] + pair[1]} gutter={[16, 16]}>
                                        {pair.map((item: any, index: any) => (
                                            <Col key={index} span={12}>
                                                <Space.Compact style={{ width: "100%" }}>

                                                    <Input style={{ width: '50%' }} disabled defaultValue={item} />
                                                    <Form.Item className="item" style={{ width: '50%' }} name={item}>

                                                        <Input style={{ width: '100%' }} />
                                                    </Form.Item>

                                                </Space.Compact>
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                            </>
                        ) : (
                            <>
                                {
                                    templatePair2B.map((pair: any, pairIndex: any) => (
                                        <Row key={pairIndex} gutter={[16, 16]}>
                                            {pair.map((item: any, index: any) => (
                                                <Col key={index} span={12}>
                                                    <Space.Compact style={{ width: "100%" }}>

                                                        <Input style={{ width: '50%' }} disabled defaultValue={item} />
                                                        <Form.Item className="item" style={{ width: '50%' }} name={item}>

                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>

                                                    </Space.Compact>
                                                </Col>
                                            ))}
                                        </Row>
                                    ))
                                }
                            </>
                        )
                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>


            </Modal>

            <div className="site-card-border-less-wrapper">
                <Card title={
                    <>
                        <Link to="/history" style={{ float: "right", marginTop: "20px" }}>view history </Link>
                        <h1>Upload PR/2B Data</h1>

                    </>
                } bordered={false} style={{ width: "99%" }}>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <h3>Select CSV/Excel File</h3>
                            <Upload {...props_multi_invoice} >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Col>

                        <Col span={12}>
                            <div>
                                <h3>Select Financial Year</h3>
                                <Select
                                    id="financialYear"
                                    style={{ width: "80%" }}
                                    placeholder="Select Year"
                                    size="large"
                                    onChange={(e)=>{
                                        setFY(e)
                                    }}
                                >
                                    {years.map((year) => (
                                        <Option key={year} value={year}>
                                            {year}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>


                        <Col span={24} style={{ marginTop: "50px", marginBottom: "30px" }}>
                            <div>
                                <h3>Select Document Type</h3>
                                <Radio.Group onChange={(e) => {
                                    setTemplateType(e.target.value)

                                }} defaultValue={optionsRadio[0]} buttonStyle="solid">
                                    {optionsRadio.map((option) => (
                                        <Radio.Button key={option} value={option}>
                                            {option}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </div>

                        </Col>

                        <Col span={12}>
                            <h3 style={{ marginBottom: 10 }}>Select Template</h3>
                            <Button style={{ marginBottom: 10 }} onClick={showModal} >Add template</Button>
                            {
                                mapTemplate.length <= 0 ? (
                                    <>
                                        <div style={{ color: "red" }}>You haven't added any template yet</div>
                                    </>
                                ) : (
                                    <Select size="large" defaultValue="Options.." style={{ width: "100%" }} onChange={(e)=>{
                                        setInvoiceType(e)
                                    }}>

                                        {mapTemplate.map((item: any) => {
                                            return (
                                                <Option value={item.template_name} key={item.template_name}>
                                                    {item.template_name}
                                                </Option>
                                            )
                                        })}

                                    </Select>
                                )
                            }

                        </Col>
                    </Row>

                    <div>
                        <Button style={{ marginTop: "50px" }}
                            className='bob_color'
                            onClick={
                                async () => {
                                    setIsLoading(true)
                                    try {
                                        const formData = new FormData();
                                        formData.append('file', multiinvoice ? multiinvoice : invoice);
                                        formData.append('year', FY);
                                        formData.append('template_name', invoiceType);
                                        formData.append('type', templateType);
                                        const response = await axios.post("/api/upload-data", formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }
                                        });
                                        toast.success("Submitted Successfully!");
                                        setIsLoading(false)
                                        console.log(response); // Success message

                                    } catch (error) {
                                        setIsLoading(false)
                                        toast.error("Error occurred while submitting, please check the mapped columns properly and also please check the date format ");
                                        console.error(error); // Error message
                                    }

                                }
                            }
                            type="primary">{isLoading ? <Spin indicator={<LoadingOutlined style={{ color: 'white' }} />} /> : 'Submit'}</Button>
                    </div>

                </Card>
            </div >
        </>
    )
}

export default UploadData