import { Outlet, Navigate } from 'react-router-dom'
import axios from 'axios'
import { useState, useEffect } from 'react'
import FullScreenLoader from './FullScreenLoader'
import { setUserData } from '../features/User/userSlice'
import { useDispatch } from 'react-redux'



const PrivateRoutes = () => {
  const [auth, setauth] = useState(true)
  const [loading, setloading] = useState(true)
  const dispatch=useDispatch()

  useEffect(() => {
    const authRequest = async () => {
      let authenticated = false

      await axios.post('/api/is-authenticated').then(response => {
        authenticated = response.data.authenticated
        setloading(false)
        setauth(authenticated)
        dispatch(setUserData({name:JSON.parse(response.data.name),email:JSON.parse(response.data.email),role:JSON.parse(response.data.role)}))
        
      })
      // console.log("Here-3")

      // if (userInfo.is_empty) {
      //   await axios.post('/api/userinfo').then((response: any) => {

      //     if (response.data.success) {
      //       console.log(">>>>>>", response.data)
      //       let userInfo = response.data.user_info;
      //       dispatch(updateUserInfo({
      //         ...userInfo,
      //         is_empty: false
      //       }))
      //     }

      //   })
      // }

    }

    authRequest()

  }, [])




  if (loading) {
    return <><FullScreenLoader></FullScreenLoader></>

  }
  else {
  }
  return (
    auth ? <Outlet /> : <Navigate to="/login" />
  )
}

export default PrivateRoutes
