import Card from "antd/es/card/Card";
import { Upload, message, Button, Select } from 'antd';

import "../assets/styles/ITCReconsillationUtility.css"
import DragAndDropUploader from "../components/DragAndDropUploader";
import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import FullScreenLoader from "../components/FullScreenLoader";

const { Option } = Select;


function ITCReconsillationUtility() {
  const [File2b, setFile2b] = useState<any>();
  const [FilePR, setFilePR] = useState<any>();
  const [FY, setFY] = useState<any>("2022");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const on2BFileChange = (file:any) => {
    // You can add custom file validation here if needed.
    console.log("File",file)
    setFile2b(file.file);
  };
  const on2BRemove = (file:any) => {


    setFile2b(null);
  };
  const onPRFileChange = (file:any) => {
    // You can add custom file validation here if needed.
    console.log("File",file)
    setFilePR(file.file);
  };
  const onPRRemove = (file:any) => {


    setFilePR(null);
  };
  
  async function submitReconcile(){
      console.log(FY)
      console.log(File2b)
      console.log(FilePR)
      setIsLoading(true)
      try {
        const formData = new FormData();
        formData.append('books',FilePR );
        formData.append('returns', File2b);
        formData.append('fy', FY);

        let url = "/api/reconcile"
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        toast.success("Submitted Successfully!");
        setTimeout(() => {
          toast.info("Your file is processing now!");
        }, 2000);
        setIsLoading(false)
        console.log(response); // Success message

      } catch (error) {
        setIsLoading(false)
        toast.error("Error occurred while submitting");
        console.error(error); // Error message
      }
  }
  return (

    <div className="site-card-border-less-wrapper">
      <Card bordered={false} style={{ width: "99%" }}>
        <Button style={{ float: "right" }}>{"Download 2B from portal --->"}</Button>
        <h2>ITC Reconcillation</h2>
        <div className="wrapper">
          <div className="block-container" style={{margin:"0 auto",textAlign:"center"}}>
            <DragAndDropUploader onChange={on2BFileChange} onRemove={on2BRemove}  quoteMessage="" mainMessage="Upload 2B" />
          <span>Click <a target="blank" href="https://docs.google.com/spreadsheets/d/1H-elKG9bJVzNJVMTX4ATcYB79T_5B72o/edit?usp=drive_link&ouid=104291848247303712343&rtpof=true&sd=true" >here</a> to see the sample 2B file</span>

          </div>
          <div className="line-container">
            <h2 className="option-1" >Option 1 (upload data)</h2>
            <div style={{display:"flex",alignItems:"center"}} >
              <h3 style={{marginRight:"10px",color:"grey"}}>Select FY : </h3>
              <Select defaultValue="2022" onChange={e=>{
                console.log(e)
                setFY(e)
                }}>
                <Option  value="2019">2019</Option>
                <Option value="2020">2020</Option>
                <Option value="2021">2021</Option>
                <Option value="2022">2022</Option>
                <Option value="2023">2023</Option>
              </Select>
            </div>

            <div className="line"></div>
          </div>
          <div className="block-container" style={{margin:"0 auto",textAlign:"center"}}>
            <DragAndDropUploader         onChange={onPRFileChange}
        onRemove={onPRRemove}  quoteMessage="" mainMessage="Upload Purchaseregister" />
          <span>Click <a href="https://docs.google.com/spreadsheets/d/11G54MgPmVL5lEN8i6QUM1Qegz-R14U8s/edit?usp=sharing&ouid=104291848247303712343&rtpof=true&sd=true"  target="blank">here</a> to see the sample PR file</span>
          </div>
        </div>
        <div className="ORText">
          OR

        </div>
        <div className="btn-wrapper">
          <h2 className="option-1" >Option 2</h2>
          <Button className="btn-1 btn-grey">Get Data From PR Report and 2B</Button>
        </div>
        <Button onClick={e=>{
          submitReconcile();
        }} disabled={isLoading} className="btn-submit" >

          {!isLoading?"Submit":"Please wait...."}
        </Button>
      </Card>
    </div >
  );

}

export default ITCReconsillationUtility