import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Modal, Button, Tag, Row, Col, Table, Spin, Input, Typography, Select, Checkbox, Pagination, Tabs, Steps, Popover, Form, Radio, DatePicker } from "antd";
import { AgGridReact } from "ag-grid-react";
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import axios from "axios";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import { useSelector } from "react-redux";
import type { StepsProps } from 'antd';


const { Option } = Select;
const { Text } = Typography;
const statusOptions = ['Validated', 'In-Valid', 'Processing..','Not invoice'];
const { TabPane } = Tabs;

const ViewInvoicePage = (props: any) => {
  const gridRef = useRef<AgGridReact>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [gridApi, setGridApi] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [originalInvoiceData, setOriginalInvoiceData] = useState<any>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStockModalVisible, setIsStockModalVisible] = useState(false);
  const [isExtractedDataLoading, setExtractedDataLoading] = useState(true)
  const [modelData, setModelData] = useState<any>()
  const [stockModelData, setStockModelData] = useState<any>()
  const [allExtractedData, setAllExtractedData] = useState<any>()
  const [isDataCorrected, setIsDataCorrected] = useState(false)
  const [isDeleteModelVisible, setIsDeleteModelVisible] = useState(false)
  const [currentInvoiceID, setCurrentInvoiceID] = useState<any>()
  const [pdfUrl, setPdfUrl] = useState<any>()
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<string>()
  const [reloadGrid, setReloadGrid] = useState<string>()
  const [pageNumber, setPageNumber] = useState(1);
  const [toDeleteInvoiceId, setToDeleteInvoiceId] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const pageSize = 50; // number of items per page
  const [activeTabKey, setActiveTabKey] = useState('Un-Reviewed'); // Initial active tab key
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  const [gstinActiveStatus, setGstinActiveStatus] = useState("")
  const [gstinType, setGstinType] = useState("")
  const [companyType, setCompanyType] = useState("")
  const [einvoiceApplicability, setEinvoiceApplicability] = useState("")
  const [einvoiceComplied, setEinvoiceComplied] = useState("")
  const [einvoiceIRN, setEinvoiceIRN] = useState("")
  const [IRNValidated, setIRNValidated] = useState("")
  const [IRNGeneratedDateAndTime, setIRNGeneratedDateAndTime] = useState("")
  const [IRPName, setIRPName] = useState("")
  const [QRCodeInvoiceValue, setQRCodeInvoiceValue] = useState("")
  const [QRCodeItemCount, setQRCodeItemCount] = useState("")
  const [QRCodeInvoiceNumber, setQRCodeInvoiceNumber] = useState("")
  const [QRCodeInvoiceDate, setQRCodeInvoiceDate] = useState("")
  const [hsnAndRsnMatch, setHsnAndRsnMatch] = useState("")
  const [reverseChargeApplicable, setReverseChargeApplicable] = useState("")
  const [itcIneligible, setItcIneligible] = useState("")

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);

    const filteredData = originalInvoiceData.filter((item:any) => item.review_status === key)
    setInvoiceData(filteredData);


  };

  const customDot: StepsProps['progressDot'] = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
    >
      {dot}
    </Popover>
  );

  const steps = [
    {
      title: 'Step 1',
      content: 'Content of Step 1',
    },
    {
      title: 'Step 2',
      content: 'Content of Step 2',
    },
    {
      title: 'Step 3',
      content: 'Content of Step 3',
    },
  ];

  const next = () => {
    if (currentStep === 2) {
      setCurrentStep(0);
      return
    }
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    if (currentStep === 0) {
      setCurrentStep(2);
      return
    }
    setCurrentStep(currentStep - 1);
  };

  const handlePageChange = (page: any) => {
    setIsFullScreenLoading(true)
    axios.post('/api/get-invoice-report', { page: page })
      .then((response: any) => {
        setCurrentPage(page)
        let formattedDataResp=response.data.data.map(formatDate)
        formattedDataResp=formattedDataResp.filter((item:any) => item.review_status === activeTabKey)
        setInvoiceData(formattedDataResp)
        
        setOriginalInvoiceData(response.data.data.map(formatDate))

      })
      .catch((error: any) => {
        setIsFullScreenLoading(false)
        toast.error("Error occurs")
        console.error(error)

      });
  };
  const handleInputChange = (value: any, dataIndex: any, index: number) => {
    // Update the corresponding data in your dataSource
    let updatedDataSource = stockModelData
    updatedDataSource[index][dataIndex] = value;
    // const updatedDataSource = stockModelData.map((item: any) => {
    //   if (item.key === recordKey) {
    //     return { ...item, [dataIndex]: value };
    //   }
    //   return item;
    // })

    setStockModelData([...updatedDataSource]);
  };



  const stockColumns = [

    {
      title: 'SAC Code',
      dataIndex: 'sac_code',
      key: 'sac_code',
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => {
            console.log("----ROW INDEX->", rowIndex)
            handleInputChange(e.target.value, 'sac_code', rowIndex)
          }}
        />
      ),
    },
    {
      title: 'SAC Code',
      dataIndex: 'item_sac_code',
      key: 'item_sac_code',
      render: (text: string) => <Text style={{ whiteSpace: 'pre-wrap' }}>{text}</Text>,
    },
    {
      title: 'Description of Goods/Service',
      dataIndex: 'item_description',
      key: 'item_description',
      width: 250,
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => handleInputChange(e.target.value, 'description_of_goods_service', rowIndex)}
        />
      ),
    },
    {
      title: 'Item Quantity',
      dataIndex: 'item_qty',
      key: 'item_qty',
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => handleInputChange(e.target.value, 'item_qty', rowIndex)}
        />
      ),
    },
    {
      title: 'Item Unit of Measurement',
      dataIndex: 'item_unit_of_measurement',
      key: 'item_unit_of_measurement',
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => handleInputChange(e.target.value, 'item_unit_of_measurement', rowIndex)}
        />
      ),
    },
    {
      title: 'Item Rate',
      dataIndex: 'item_rate',
      key: 'item_rate',
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => handleInputChange(e.target.value, 'item_rate', rowIndex)}
        />
      ),
    },
    {
      title: 'Item Amount',
      dataIndex: 'item_amt',
      key: 'item_amt',
      render: (text: any, record: any, rowIndex: number) => (
        <Input
          value={text}
          style={{ whiteSpace: 'pre-wrap' }}
          onChange={(e) => handleInputChange(e.target.value, 'item_amt', rowIndex)}
        />
      ),
    },
  ];

  let userType = useSelector((state: any) => state.user.role)

  const handleStatusChange = async (value: any) => {
    setSelectedStatus(value);
    try {
      const response = await axios.post('/api/filter-status', {
        invoice_status: value
      });
      console.log(response.data); // Handle the response data here
      setInvoiceData(response.data.data.map(formatDate))
      setOriginalInvoiceData(response.data.data.map(formatDate))
    } catch (error) {
      console.error(error); // Handle any errors during the request
    }
  };

  useEffect(() => {

    axios.post('/api/get-invoice-report').then(response => {
      console.log("----->-->>>===>>>-->",response)
      let formattedDataResp=response.data.data.map(formatDate)
      formattedDataResp=formattedDataResp.filter((item:any) => item.review_status === "Un-Reviewed")
      setInvoiceData(formattedDataResp)
      setOriginalInvoiceData(response.data.data.map(formatDate))
      setTotalCount(response.data.count)
    }).catch(err => {
      console.log(err)
    })

  }, [isModalVisible, reloadGrid])

  const columnsModelTable = [
    {
      title: 'Details',
      dataIndex: '',
      key: 'details',
      render: (record: any) => (
        <>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "10px" }}>
            <span>{record.label} :</span> <Input value={record.value} onChange={
              (e: any) => {
                console.log(e.target.value);
                record.value = e.target.value
                let tempModalData = [...modelData]
                let allExtractedDataTemp = allExtractedData

                tempModalData.forEach((item: any, index: number) => {
                  if (item.label === record.label) {
                    tempModalData[index]["value"] = e.target.value
                    allExtractedDataTemp[record.label] = e.target.value
                    setIsDataCorrected(true)
                    setAllExtractedData(allExtractedDataTemp)
                  }
                })
                setModelData(tempModalData)
              }
            } style={{ width: "60%", height: "30px" }} />
          </div>
        </>
      ),
    },
  ];


  const formatDate = (value: any) => {
    let date = value.submitted_on
    console.log("map", value)
    let formattedDate: any = new Date(date);
    formattedDate = formattedDate.toISOString().substring(0, 10);
    value.submitted_on = formattedDate
    return value
  }
  const downloadBinaryFile = (binaryData: any) => {
    const blob = new Blob([binaryData]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv");
    link.click();
  };
  const description = 'You can hover on the dot.';
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'validated':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'Pending approval':
        return 'yellow';
      case 'Pending validation':
        return 'orange';
      case 'Approved':
        return 'green';
      case 'PAPP':
        return 'orange';
      case 'PAPR':
        return 'orange';
      case 'Rejected':
        return 'red';
      case 'Processing..':
        return 'gold';
      case 'Validation Failed':
        return 'red';
      case '':
        return 'red';
      default:
        return '';
    }
  };
  const columnDef = [
    {
      headerName: 'invoice id',
      field: 'invoice_id',
      hide: true,
      cellStyle: { textAlign: 'center' },
      headerClass: 'centered-header'
    },
    {
      headerName: 'invoice name',
      field: 's3_invoice_filename',
      hide: true,
      cellStyle: { textAlign: 'center' },
      headerClass: 'centered-header'
    },
    {
      headerName: 'Invoice',
      field: 'filename',
      cellRendererFramework: (params: any) => (
        <div>
          <Button style={{ color: "#F4811F", marginLeft: "-15px" }} type="link" onClick={
            e => {
              console.log("HHHHHH >", e)
              if (params.data.invoice_status == "Processing" || params.data.invoice_status == "Not invoice") {
                return
              }
              setIsModalVisible(true)
              let data = {
                user_type: userType,
                invoice_id: params.data.invoice_id
              }
              axios.post("/api/get-extracted-invoice-data", data).then(
                response => {
                  let data = JSON.parse(response.data.data.replace(/NaN/g, 'null'))
                  console.log("Response Extracted Data =>>>>>>>>", JSON.parse(response.data.data.replace(/NaN/g, 'null')));
                  setExtractedDataLoading(false)
                  let modelDataArr = []
                  setCurrentInvoiceID(data.invoice_id)
                  setPdfUrl(data.file_url)

                  for (let key in data) {
                    if (key === "invoice_status") {
                      setSelectedInvoiceStatus(data[key])
                    }
                    if (key === "filename" || key === "invoice_value" || key === "taxable_value" || key === "cgst" || key === "sgst" || key === "igst" || key === "supplier_gstin" || key === "invoice_date" || key === "invoice_no" || key === "total_tax" || key === "supplier_name"|| key === "irn") {
                      modelDataArr.push(
                        {
                          key: key,
                          label: key,
                          value: data[key],
                        }
                      )
                    } else {
                      continue
                    }

                  }

                  setModelData(modelDataArr)
                  setStockModelData(data.stock_items)
                  setIRPName(data.iss)
                  setGstinActiveStatus(data.gstin_status)
                  setGstinType(data.gstin_type)
                  setCompanyType(data.company_type)
                  setEinvoiceApplicability(data.einvoice_enabled)
                  setEinvoiceComplied(data.qr_irn !== "nan" && data.qr_irn ? 'yes' : "no")
                  setEinvoiceIRN(data.qr_irn)
                  setIRNValidated(data.qr_irn !== "nan" && data.qr_irn ? 'yes' : "no")
                  setIRNGeneratedDateAndTime(data.qr_irndt)
                  setQRCodeInvoiceValue(data.qr_totinvval) 
                  setQRCodeItemCount(data.qr_itemcnt)
                  setQRCodeInvoiceNumber(data.qr_docno)
                  setQRCodeInvoiceDate(data.qr_docdt)
                  setHsnAndRsnMatch(data.qr_mainhsncode !== "nan" && data.qr_mainhsncode ? "yes" : "no")

                  setAllExtractedData(data)
                }
              )

            }

          }>{params.data.filename}</Button>

        </div>
      ),
      cellStyle: { textAlign: 'center' },
      headerClass: 'centered-header'
    },
    {
      headerName: 'Invoice type',
      field: 'invoice_type',
      cellStyle: { textAlign: 'center' },
      headerClass: 'centered-header'
    },
    {
      headerName: 'Upload Date',
      field: 'submitted_on'
    },
    {
      headerName: 'Status',
      field: 'invoice_status',
      cellRendererFramework: (params: any) => {
        console.log("----------------------------------------------------------------")
        console.log(params)
        console.log("----------------------------------------------------------------")
        return (<Tag style={{ height: 20 }} color={getStatusColor(params.value)}>{params.value}</Tag>)
      },
    },
    {
      headerName: '',
      field: 'action',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <Button onClick={() => {
              setToDeleteInvoiceId(params.data.invoice_id)

              setIsDeleteModelVisible(true)
            }} type="link">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" className="bi bi-trash-fill" viewBox="0 0 16 16">
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>
            </Button>

          </>
        )
      },
    },
    {
      headerName: '',
      field: 'view_invoice',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <Button onClick={
              e => {
                console.log("HHHHHH >", e)
                if (params.data.invoice_status == "Processing" || params.data.invoice_status == "Not invoice") {
                  return
                }
                setIsModalVisible(true)
                let data = {
                  user_type: userType,
                  invoice_id: params.data.invoice_id
                }
                axios.post("/api/get-extracted-invoice-data", data).then(
                  response => {
                    let data = JSON.parse(response.data.data.replace(/NaN/g, 'null'))
                    console.log("response=>>>>>>>>", JSON.parse(response.data.data.replace(/NaN/g, 'null')));
                    setExtractedDataLoading(false)
                    let modelDataArr = []
                    setCurrentInvoiceID(data.invoice_id)
                    setPdfUrl(data.file_url)

                    for (let key in data) {
                      if (key === "invoice_status") {
                        setSelectedInvoiceStatus(data[key])
                      }
                      if (key === "filename" || key === "invoice_value" || key === "taxable_value" || key === "cgst" || key === "sgst" || key === "igst" || key === "supplier_gstin" || key === "invoice_date" || key === "invoice_no" || key === "total_tax" || key === "supplier_name") {
                        modelDataArr.push(
                          {
                            key: key,
                            label: key,
                            value: data[key],
                          }
                        )
                      } else {
                        continue
                      }

                      // SEQUENCE:
                      // filename,
                    }
                    setModelData(modelDataArr)
                    setStockModelData(data.stock_items)
                    console.log("Model data")
                    setIRPName(data.iss)
                    setGstinActiveStatus(data.gstin_status)
                    setGstinType(data.gstin_type)
                    setCompanyType(data.company_type)
                    setEinvoiceApplicability(data.einvoice_enabled)
                    setEinvoiceComplied(data.qr_irn !== "nan" && data.qr_irn ? 'yes' : "no")
                    setEinvoiceIRN(data.qr_irn)
                    setIRNValidated(data.qr_irn !== "nan" && data.qr_irn ? 'yes' : "no")
                    setIRNGeneratedDateAndTime(data.qr_irndt)
                    setQRCodeInvoiceValue(data.qr_totinvval)                      
                    setQRCodeItemCount(data.qr_itemcnt)
                    setQRCodeInvoiceNumber(data.qr_docno)
                    setQRCodeInvoiceDate(data.qr_docdt)
                    setHsnAndRsnMatch(data.qr_mainhsncode !== "nan" && data.qr_mainhsncode ? "yes" : "no")


                  }
                )
                console.log(params)

              }

            } type="link">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            </Button>

          </>
        )
      },
    },
    {
      headerCheckboxSelection: true, checkboxSelection: true, width: 50,
      headerName: "",
      field: 'checkbox',

    },

  ];
  const handleSelectionChanged = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);

    // Run your custom function here using the selected data
    const invoiceIds = selectedData.map((item: any) => item.invoice_id);
    setSelectedInvoices([...invoiceIds])
    console.log('invoice Data:', invoiceIds);
  };
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: false,

    // Default Column Properties

  }), []);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={(
          <>
            {selectedInvoiceStatus === "Validated" ? (

              <Button onClick={() => {
                axios.post("/api/send-for-approval", { "invoice_id": currentInvoiceID }).then(response => {
                  if (response.data.success) {
                    setIsModalVisible(false)
                    toast.success("Send Successfully!")
                  }
                })
              }} style={{ marginRight: 16 }}>
                Send for approval
              </Button>
            ) : (
              <></>
            )}
            {/* <Button onClick={() => { 
                  axios.post("/api/send-for-approval",{"invoice_id":currentInvoiceID}).then(response=>{
                    if (response.data.success){
                      setIsModalVisible(false)
                      toast.success("Send Successfully!")
                    }
                  })
                }} style={{ marginRight: 16 }}>
                  Save
                </Button> */}
          </>
        )}
        width="100vw"
        style={{ top: "0px", padding: 10 }}
      >
        {isExtractedDataLoading ? (
          <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spin indicator={<LoadingOutlined style={{ color: '#F4811F' }} />} size="large" />
          </div>
        ) : (
          <>
            <div style={{ height: "90vh", padding: 20 }}>
              <Row style={{ padding: "4px 10px 20px 10px" }}>
                <Col span={1}>
                  <Button onClick={prev} type="primary" className="btn btn-sm" size="small" style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                  </Button>
                </Col>
                <Col span={22}>
                  <Steps
                    current={currentStep}
                    progressDot={customDot}
                    items={[
                      {
                        title: 'Invoice',
                      },
                      {
                        title: 'Inventory',
                      },
                      {
                        title: 'Compliance',
                      },
                    ]}
                  />
                </Col>
                <Col span={1}>
                  <Button onClick={next} type="primary" className="btn btn-sm" size="small" style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                    </svg>
                  </Button>
                </Col>

              </Row>

              <Row gutter={16} >
                <Col span={currentStep === 0 ? 12 : 0} style={{ overflow: "hidden" }}>
                  {
                    currentStep === 0 ? (
                      pdfUrl.match(/\.([^.]*$)/)[1].split("?")[0] === "pdf" ?
                        (
                          <iframe
                            src={pdfUrl}
                            width="100%"
                            height="90%"
                          />
                        ) : (
                          <img
                            src={pdfUrl}
                            style={{ height: "70%", width: "100%", "objectFit": "scale-down" }}
                          />
                        )
                    ) : (
                      <></>
                    )
                  }



                </Col>
                <Col span={currentStep === 0 ? 12 : 24} >

                  {currentStep === 0 ? (
                    <>
                      <Table scroll={{ y: "60vh" }} dataSource={modelData} columns={columnsModelTable} pagination={false} bordered={true} style={{ marginTop: "20px" }} />
                    </>
                  ) : (
                    currentStep === 1 ? (
                      <Table scroll={{ y: "70vh" }} dataSource={stockModelData} columns={stockColumns} pagination={false} bordered={true} style={{ marginTop: "20px" }} />
                    ) : (
                      <>
                        <Form {...layout} style={{ height: "80vh", overflow: "auto" }}>
                          <h2>Supplier Compliance</h2>

                          <Form.Item label="GSTIN Active Status">
                            <Input
                              value={gstinActiveStatus}
                              placeholder="GSTIN Active Status"
                              onChange={(e) => {
                                setGstinActiveStatus(e.target.value)
                              }}
                            />
                          </Form.Item>

                          <Form.Item label="GSTIN Type">
                            <Input
                              value={gstinType}
                              placeholder="GSTIN Type"
                              onChange={(e) => setGstinType(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="Company Type">
                            <Input
                              value={companyType}
                              placeholder="Company Type"
                              onChange={(e) => setCompanyType(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="Einvoice Applicability">
                            <Radio.Group
                              value={einvoiceApplicability}
                              onChange={(e) => setEinvoiceApplicability(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <h2>E Invoice Related</h2>

                          <Form.Item label="Einvoice Complied ?">
                            <Radio.Group
                              value={einvoiceComplied}
                              onChange={(e) => setEinvoiceComplied(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item label="Einvoice IRN">
                            <Input
                              placeholder="Einvoice IRN"
                              value={einvoiceIRN}
                              onChange={(e) => setEinvoiceIRN(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="IRN Validated ?">
                            <Radio.Group
                              value={IRNValidated}
                              onChange={(e) => setIRNValidated(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item label="IRN Generated date and time">
                            <Input
                              placeholder="IRN Generated date and time"
                              value={IRNGeneratedDateAndTime}
                              onChange={(e) => setIRNGeneratedDateAndTime(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="IRP Name">
                            <Input
                              value={IRPName}
                              placeholder="IRP Name"
                              onChange={(e) => setIRPName(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="QR Code - Invoice Value">
                            <Input
                              value={QRCodeInvoiceValue}
                              placeholder="QR Code - Invoice Value"
                              onChange={(e) => setQRCodeInvoiceValue(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="QR Code - Item Count">
                            <Input
                              value={QRCodeItemCount}
                              placeholder="QR Code - Item Count"
                              onChange={(e) => setQRCodeItemCount(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="QR Code - Invoice Number">
                            <Input
                              value={QRCodeInvoiceNumber}
                              placeholder="QR Code - Invoice Number"
                              onChange={(e) => setQRCodeInvoiceNumber(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item label="QR Code - Invoice Date">
                            <Input
                              value={QRCodeInvoiceDate}
                              placeholder="QR Code - Invoice Date"
                              onChange={(e) => setQRCodeInvoiceDate(e.target.value)}
                            />
                          </Form.Item>

                          <h2>Other - GST Related</h2>

                          <Form.Item label="HSN and Rate Match ?">
                            <Radio.Group
                              value={hsnAndRsnMatch}
                              onChange={(e) => setHsnAndRsnMatch(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item label="Reverse Charge Applicable ?">
                            <Radio.Group
                              value={reverseChargeApplicable}
                              onChange={(e) => setReverseChargeApplicable(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          <Form.Item label="ITC Ineligible As per Sec 17 (5) ?">
                            <Radio.Group
                              value={itcIneligible}
                              onChange={(e) => setItcIneligible(e.target.value)}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: 60, marginBottom: 60 }}>

                            <Button onClick={(e: any) => {
                              console.log("+++++++++++++++++++++++++++++++++++",allExtractedData)
                              let data: any = allExtractedData

                              // for (let index = 0; index < modelData.length; index++) {
                              //   let element: any = modelData[index]
                              //   data[element["key"]] = element.value

                              // }

                              data["stock_items"] = stockModelData
                              data["gstin_status"]=gstinActiveStatus
                              data["gstin_type"]=gstinType
                              data["company_type"]=companyType
                              data["einvoice_enabled"]=einvoiceApplicability
                              data["qr_irn"]=einvoiceIRN
                              data["qr_irndt"]=IRNGeneratedDateAndTime
                              data["qr_totinval"]=QRCodeInvoiceValue
                              data["qr_itemcnt"]=QRCodeItemCount
                              data["qr_docno"]=QRCodeInvoiceNumber
                              data["qr_docdt"]=setQRCodeInvoiceDate
                              axios.post("/api/update-invoice", data).then(res => {

                              })
                              toast.success("Values Updated!");
                            }} type="primary" style={{ width: "20%" }}>

                              Save

                            </Button>
                          </div>
                        </Form>
                      </>
                    )
                  )}

                </Col>
              </Row>
            </div>
          </>
        )}


      </Modal>
      <Modal
        open={isStockModalVisible}
        onCancel={() => setIsStockModalVisible(false)}
        width="100%"
        style={{ marginTop: "-70px" }}
      >
        {isExtractedDataLoading ? (
          <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spin indicator={<LoadingOutlined style={{ color: '#F4811F' }} />} size="large" />
          </div>
        ) : (
          <>
            <div style={{ height: "100vh" }}>
              <Row style={{ height: '100%' }}>

                <Col span={24} >
                  <Table scroll={{ y: "70vh" }} dataSource={stockModelData} columns={stockColumns} pagination={false} bordered={true} style={{ marginTop: "20px" }} />

                </Col>

              </Row>
            </div>
          </>
        )}


      </Modal>
      <Card>
        <div style={{ width: "100%" }}>

          <Button onClick={e => {
            axios.post('/api/download-invoice').then(res => {
              console.log(res)
              downloadBinaryFile(res.data);
            })
          }} type="primary" style={{ float: "right", marginTop: "0", cursor: "pointer", marginLeft: 10 }}>
            Download report
          </Button>
          <Button onClick={(e) => {
            axios.post('/api/delete-selected-invoice', { "invoice_ids": selectedInvoices }).then(response => { window.location.reload(); })
          }} style={{ float: "right" }} type="primary" >Delete Selected</Button>
        </div>
        <div>

          <h2 style={{ marginTop: "0", color: "grey" }}>Invoice Report</h2>
        </div>
        <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
          

          <TabPane tab="Unprocessed"  key="Un-Reviewed">

          </TabPane>

          <TabPane tab="Reviewed" key="Reviewed">

          </TabPane>

          <TabPane tab="Accounted" key="accounted">

          </TabPane>


        </Tabs>
        <div>


        </div>
        <div className="ag-theme-material" style={{ height: "50vh" }}>

          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={invoiceData} // Row Data for Rows
            columnDefs={columnDef} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection='multiple' // Options - allows click selection of rows
            // Optional - registering for Grid Event
            rowHeight={30}
            headerHeight={30}
            onGridReady={onGridReady}
            onSelectionChanged={handleSelectionChanged}
          />

        </div>
        <Pagination
          style={{ marginTop: '20px' }}
          className="custom-pagination"

          current={currentPage}
          pageSize={pageSize}
          total={totalCount}
          onChange={handlePageChange}
          pageSizeOptions={[]}
        />

      </Card>
      <Modal
        title="Are you sure you want to delete this item?"
        open={isDeleteModelVisible}
        onOk={() => {
          axios.post('/api/delete-invoice', { "invoice_id": toDeleteInvoiceId }).then(response => {

            setIsDeleteModelVisible(false)
            let randomString: string = Math.random().toString(36).substring(2, 12);
            setReloadGrid(randomString)
          })
        }}
        onCancel={() => { setIsDeleteModelVisible(false) }}
      >
        <p>This action cannot be undone.</p>
      </Modal>
    </>
  );
};

export default ViewInvoicePage;