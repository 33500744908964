import Card from "antd/es/card/Card";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import { Button } from "antd";
import { toast } from 'react-toastify';


function ReconcillationReport() {

    const gridRef = useRef<AgGridReact>(null);
    const [gridApi, setGridApi] = useState<any>();
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [selectedInvoices, setSelectedInvoices] = useState<any>([]);

    useEffect(() => {
        axios.post("/api/get-reco-report").then((response) => {
            console.log(response.data);
            setInvoiceData(response.data.data);
        })
    }, [])
    const formatDate = (value: any) => {
        let date = value.submitted_on
        let formattedDate: any = new Date(date);
        formattedDate = formattedDate.toISOString().substring(0, 10);
        value.submitted_on = formattedDate
        return value
      }
    const columnDef = [
        {
            headerName: 'Submitted On',
            field: 'submitted_on',
            wrapText: true, // Enable text wrapping
            autoHeight: true, // Auto-adjust row height to fit wrapped content
            cellStyle:{"lineHeight":"1.5"},
            cellRendererFramework: (params:any) => {
                let x=formatDate(params.data)
                console.log("-------->",params.value)
                const dateTimeValue = new Date(params.value);

                // Format to a human-readable string
                const formattedDateTime = dateTimeValue.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long', // Use 'short' or 'numeric' for different styles
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // To display in 12-hour format, set to false for 24-hour
                timeZoneName: 'short', // Optional: to display time zone
                });
                return formattedDateTime
            },
        },
        {
            headerName: 'Reconciliation Status',
            field: 'recon_status',
            wrapText: true, // Enable text wrapping
            autoHeight: true, // Auto-adjust row height to fit wrapped content
            cellStyle:{"lineHeight":"1.2"}
        },
        {
            headerName: 'Download Books',
            cellRendererFramework: (params:any) => (
                <Button onClick={e=>{
                    if (params.data.recon_status=="Processing") {
                        toast.error("Still processing")
                        return
                    }
                    axios.post("/api/generate-download-link", { "filename": params.data.books_s3_filename })
                    .then((response: any) => {
                      window.location.href = response.data.url;
                    });
                }} style={{height:"20px",width:"80px",padding:"0",display:"flex",alignItems:"center",justifyContent:"center"}} >
                    Download
                </Button>
            ),
        },
        {
            headerName: 'Download Returns',
            cellRendererFramework: (params:any) => (
                <Button onClick={e=>{
                    if (params.data.recon_status=="Processing") {
                        toast.error("Still processing")
                        return
                    }
                    axios.post("/api/generate-download-link", { "filename": params.data.ret_s3_filename })
                    .then((response: any) => {
                      window.location.href = response.data.url;
                    });
                }}  style={{height:"20px",width:"80px",padding:"0",display:"flex",alignItems:"center",justifyContent:"center"}} >
                    Download
                </Button>
            ),
        },
        {
            headerName: 'Download Recon-Report ',
            cellRendererFramework: (params:any) => (
                <Button onClick={e=>{
                    if (params.data.recon_status=="Processing") {
                        toast.error("Still processing")
                        return
                    }
                    axios.post("/api/generate-download-link", { "filename": params.data.reco_s3_filename })
                    .then((response: any) => {
                      window.location.href = response.data.url;
                    });
                }}  style={{height:"20px",width:"80px",padding:"0",display:"flex",alignItems:"center",justifyContent:"center"}} >
                    Download
                </Button>
            ),
        },
        {
            headerName: 'Books S3 Filename',
            field: 'book_s3_filename',
            hide: true, // Hide the column
        },
        {
            headerName: 'Returns S3 Filename',
            field: 'ret_s3_filename',
            hide: true, // Hide the column
        },
        {
            headerName: 'recon S3 Filename',
            field: 'reco_s3_filename',
            hide: true, // Hide the column
        },
        {
            headerCheckboxSelection: true, checkboxSelection: true, width: 50 ,
            headerName:"",
            field: 'checkbox',
        }
        
    ];
    const onGridReady = (params: any) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };
    const handleSelectionChanged = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map((node:any) => node.data);
        
        // Run your custom function here using the selected data
        const invoiceIds = selectedData.map((item:any) => item.invoice_id);
        setSelectedInvoices([...invoiceIds])
      };
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: false,
        flex: 1
    
        // Default Column Properties
    
      }), []);


    return (

        <div className="site-card-border-less-wrapper">
            <Card bordered={false} style={{ width: "99%" }}>

                <Button  style={{float:"right"}} onClick={(e)=>{
                                axios.post('/api/delete-recon-report', { "invoice_ids": selectedInvoices }).then(response=>{window.location.reload();})
                }}>Delete Selected</Button>
                <h2>Reconcillation Report</h2>
                <div className="ag-theme-material" style={{ height: "70vh" }}>

                    <AgGridReact
                        ref={gridRef} // Ref for accessing Grid's API
                        rowData={invoiceData} // Row Data for Rows
                        columnDefs={columnDef} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        rowSelection='multiple' // Options - allows click selection of rows
                        // Optional - registering for Grid Event
                        rowHeight={30}
                        headerHeight={30}
                        onGridReady={onGridReady}
                        onSelectionChanged={handleSelectionChanged}
                    />

                </div>
            </Card>
        </div >
    );

}

export default ReconcillationReport
