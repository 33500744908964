import React from 'react'
import { Tabs, Card } from 'antd';
const { TabPane } = Tabs;

function ReconResult() {
    return (
        <Card style={{ padding: 0, minHeight: "85vh" }}>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Recon Report" key="1">
                    Content of Recon Report Tab
                </TabPane>
                <TabPane tab="Pr Extracted" key="2">
                    Content of Pr Extracted Tab
                </TabPane>
                <TabPane tab="2B Extracted" key="3">
                    Content of 2B Extracted Tab
                </TabPane>
            </Tabs>
            
        </Card>
    )
}

export default ReconResult