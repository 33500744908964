import Card from "antd/es/card/Card";
import { Form, Input, Select, Button } from 'antd';
import axios from "axios";
const { Option } = Select;


function Download2B() {
    const onFinish = (values: any) => {
        // Handle form submission here
        console.log('Form values:', values);
        axios
            .post(`/api/download-2b/`, values)
            .then((response) => {
                console.log(response);
            });
    };

    return (

        <div className="site-card-border-less-wrapper">
            <Card bordered={false} style={{ width: "99%" }}>
                <Form onFinish={onFinish} labelCol={{ span: 3 }} wrapperCol={{ span: 17 }} labelAlign="left">
                    <Form.Item label="GST Username" name="gst_username" rules={[{ required: true, message: 'Please enter GST Username' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="State CD" name="state_cd" rules={[{ required: true, message: 'Please enter State Code' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="TXN" name="txn" rules={[{ required: true, message: 'Please enter Transaction' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="GSTIN" name="gstin" rules={[{ required: true, message: 'Please enter GSTIN' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Return Period" name="ret_period" rules={[{ required: true, message: 'Please enter Return Period' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Financial Year" name="fy" rules={[{ required: true, message: 'Please enter Financial Year' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

            </Card>
        </div >
    );

}

export default Download2B
