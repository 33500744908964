/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
  Form,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import Input from "antd/es/input/Input";
import Layout from "antd/es/layout/layout";
import axios from "axios";
import { toast } from "react-toastify";

function ProfilePage() {
  const [imageURL, setImageURL] = useState<any>();
  const [, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [encryptedGSTINPassword, setEncryptedGSTINPassword] = useState<any>();
  const [companyToken, setCompanyToken] = useState("")

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];
  // Function to handle the encryption of GSTIN Password
  const handleEncryptGSTINPassword = (value: any) => {
    // Implement your encryption logic here
    // Example: const encryptedValue = encryptFunction(value);

  };

  // Function to handle form submission
  const onFinish = (values: any) => {
    // You can access the encryptedGSTINPassword here
    // values will contain Company Name, Company Name as per ERP, PAN, GSTIN, GSTIN User ID, and encryptedGSTINPassword
    console.log(values);
    axios.post("/api/company-registeration", values)
    toast.success("Successfully registered");
  };

  // useEffect(() => {
  //   axios.post("/api/get-company").then(response => {
  //     console.log(response);
  //   })

  // }, [])

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>




      <Row style={{ padding: "0 20px", marginTop: "-100px" }} gutter={[24, 20]}>
        <Col span={24} md={12} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            title={
              <>
                <h6 className="font-semibold m-0">Personal information</h6>
              </>
            }
          >
            <Input placeholder="Email" />
            <Input style={{ marginTop: "10px" }} placeholder="Name" />
            <Input style={{ marginTop: "10px" }} placeholder="Contact no" />
            <Button style={{ marginTop: "12px" }} type="primary">Update</Button>

          </Card>
        </Col>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={

              <>
                <h6 className="font-semibold m-0">Company Information</h6>
              </>

            }
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Form form={form} onFinish={onFinish}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="company_name"
                    rules={[{ required: true, message: 'Company Name is required' }]}
                  >
                    <Input placeholder="Company Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="company_name_erp"
                    rules={[{ required: true, message: 'Company Name as per ERP is required' }]}
                  >
                    <Input placeholder="Company Name as per ERP [Tally, Busy]" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company_pan"
                    rules={[{ required: true, message: 'PAN is required' }]}
                  >
                    <Input placeholder=" PAN" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="gstin">
                    <Input placeholder="GSTIN" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="gstin_user_id">
                    <Input placeholder="GSTIN User ID" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="gstin_pwd"
                    getValueFromEvent={(e) => {
                      handleEncryptGSTINPassword(e.target.value);
                      return e.target.value; // This will store the plain text value in the form
                    }}
                  >
                    <Input type="password" placeholder="GSTIN Password (Encrypt this so it won't be visible after first save)" />
                  </Form.Item>
                </Col>
              </Row>

              <Button style={{ marginTop: "12px" }} type="primary" htmlType="submit">Add Company</Button>
            </Form>

          </Card>
        </Col>
        <Col span={24}>

          <Card
            bordered={false}
            title={
              <>
                <h6 className="font-semibold m-0">My Company Token</h6>
                <h5>{companyToken}</h5>
              </>
            }
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Button onClick={e=>{
              axios.post("/api/get-token").then(response =>{

                setCompanyToken(response.data.token)
              })
            }} type="dashed">Get Token</Button>

          </Card>
        </Col>
        {/* <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Conversations</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={data}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item actions={[<Button type="link">REPLY</Button>]}>
                <List.Item.Meta
                avatar={
                  <Avatar shape="square" size={48} src={item.avatar} />
                }
                title={item.title}
                description={item.description}
                />
                </List.Item>
              )}
            />
          </Card>
        </Col> */}
      </Row>

      {/* <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">Projects</h6>
            <p>Architects design houses</p>
          </>
        }
      >
        <Row gutter={[24, 24]}>
          {project.map((p, index) => (
            <Col span={24} md={12} xl={6} key={index}>
              <Card
                bordered={false}
                className="card-project"
                cover={<img alt="example" src={p.img} />}
              >
                <div className="card-tag">{p.titlesub}</div>
                <h5>{p.title}</h5>
                <p>{p.disciption}</p>
                <Row gutter={[6, 0]} className="card-footer">
                  <Col span={12}>
                    <Button type="primary">VIEW PROJECT</Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Avatar.Group className="avatar-chips">
                      <Avatar size="small" src={profilavatar} />
                      <Avatar size="small" src={convesionImg} />
                      <Avatar size="small" src={convesionImg2} />
                      <Avatar size="small" src={convesionImg3} />
                    </Avatar.Group>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
          <Col span={24} md={12} xl={6}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader projects-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageURL ? (
                <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
      </Card> */}
    </>
  );
}

export default ProfilePage;
