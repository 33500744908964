import { useState } from 'react';
import { Table, Tag, Modal, Select, Button, Row, Col, Input } from 'antd';
import Card from 'antd/es/card/Card';

const { Option } = Select;

interface InvoiceData {
  key: string;
  sno: number;
  invoiceType: string;
  uploadedBy: string;
  uploadDate: string;
  status: any;
}


function ApproveInvoice() {
  const [modalVisible, setModalVisible] = useState(false);

  const data: InvoiceData[] = [
    {
      key: '1',
      sno: 1,
      invoiceType: 'Type A',
      uploadedBy: 'John Doe',
      uploadDate: '2022-08-01',
      status: 'Pending',
    },
    {
      key: '2',
      sno: 2,
      invoiceType: 'Type B',
      uploadedBy: 'Jane Smith',
      uploadDate: '2022-08-03',
      status: 'Approved',
    },
    {
      key: '3',
      sno: 3,
      invoiceType: 'Type C',
      uploadedBy: 'Bob Johnson',
      uploadDate: '2022-08-05',
      status: 'Partial Approved',
    },
    {
      key: '4',
      sno: 4,
      invoiceType: 'Type D',
      uploadedBy: 'Alice Lee',
      uploadDate: '2022-08-07',
      status: 'Partial Rejected',
    },
    {
      key: '5',
      sno: 5,
      invoiceType: 'Type A',
      uploadedBy: 'John Doe',
      uploadDate: '2022-08-01',
      status: 'Pending',
    },
    {
      key: '6',
      sno: 6,
      invoiceType: 'Type B',
      uploadedBy: 'Jane Smith',
      uploadDate: '2022-08-03',
      status: 'Approved',
    },
    {
      key: '7',
      sno: 7,
      invoiceType: 'Type C',
      uploadedBy: 'Bob Johnson',
      uploadDate: '2022-08-05',
      status: 'Partial Approved',
    },
    {
      key: '8',
      sno: 8,
      invoiceType: 'Type D',
      uploadedBy: 'Alice Lee',
      uploadDate: '2022-08-07',
      status: 'Partial Rejected',
    },
  ];

  const columns = [
    {
      title: 'S.no',
      dataIndex: 'sno',
      key: 'sno',
      
      sorter: (a: InvoiceData, b: InvoiceData) => a.sno - b.sno,
    },
    {
      title: 'Invoice Type',
      dataIndex: 'invoiceType',
      key: 'invoiceType',
      sorter: (a: InvoiceData, b: InvoiceData) => a.invoiceType.localeCompare(b.invoiceType),
    },
    {
      title: 'Uploaded by',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      sorter: (a: InvoiceData, b: InvoiceData) => a.uploadedBy.localeCompare(b.uploadedBy),
    },
    {
      title: 'Upload date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      sorter: (a: any, b: any) => {
        const dateA = a.uploadDate ? new Date(a.uploadDate) : null;
        const dateB = b.uploadDate ? new Date(b.uploadDate) : null;
        return dateA && dateB ? dateA.getTime() - dateB.getTime() : 0;
      },

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: InvoiceData, b: InvoiceData) => a.status.localeCompare(b.status),
      render: (status: InvoiceData['status']) => (
        <Tag color={status === 'Pending' ? 'red' : status === 'Approved' ? 'green' : 'orange'}>{status}</Tag>
      )
    },
    {
      title: 'View Invoice',
      key: 'viewInvoice',
      render: () => (
        <Button type="link" style={{ color: "grey" }} onClick={() => setModalVisible(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
          </svg>
        </Button>
      ),
    },
  ];
  const dataSourceModelTable = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
    },
  ];
  const columnsModelTable = [
    {
      title: 'Details',
      dataIndex: '',
      key: 'details',
      render: (record: any) => (
        <>
          <p>
            <b>Name:</b> {record.name}
          </p>
          <p>
            <b>Age:</b> {record.age}
          </p>
        </>
      ),
    },
  ];

  const handleApprove = () => {
    // Do something when approve button is clicked

  };

  const handleReject = () => {
    // Do something when reject button is clicked
  };

  return (
    <>
      <Card style={{width:"90%"}}>
        <h2 style={{marginTop:"0px"}}>Invoices</h2>
        <Table<InvoiceData> columns={columns} dataSource={data} scroll={{ y: 400 }} />

        <Modal
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          width="70vw"
          style={{ marginTop: "-70px" }}
        >
          <div style={{ height: "80vh" }}>
            <Row gutter={16} style={{ height: '100%' }}>
              <Col span={14} style={{ overflow: "hidden" }}>

                <object
                  data="/sample-invoice.pdf"
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >

                </object>

              </Col>
              <Col span={10}>
                <Table  dataSource={dataSourceModelTable} columns={columnsModelTable} pagination={false} bordered={true} style={{ marginTop: "20px" }} />
                <Button onClick={handleApprove} style={{ marginRight: 16 }}>
                  Approve
                </Button>
                <Button onClick={handleReject}>Reject</Button>
              </Col>

            </Row>
          </div>
          {/* put the content of the modal here */}
        </Modal>
      </Card>

    </>
  );
}

export default ApproveInvoice