interface VendorValue {
    label: string;
    value: string;
  }

export const formatDate = (value: any) => {
    let date = value.submitted_on
    console.log("map", value)
    let formattedDate: any = new Date(date);
    formattedDate = formattedDate.toISOString().substring(0, 10);
    value.submitted_on = formattedDate
    return value
  }

export async function fetchVendorList(username: string): Promise<VendorValue[]> {
    console.log('fetching vendor', username);
  
    return fetch('https://randomuser.me/api/?results=5')
      .then((response) => response.json())
      .then((body) =>
        body.results.map(
          (user: { name: { first: string; last: string }; login: { username: string } }) => ({
            label: `${user.name.first} ${user.name.last}`,
            value: user.login.username,
          }),
        ),
      );
  }