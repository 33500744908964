import React, { useState, useRef, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Upload,
  Spin,
  Tooltip,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import type { UploadProps } from "antd";
import type { SelectProps } from "antd/es/select";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

function GSTR2A() {
  const { Option } = Select;
  const [file, setFile] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const props_invoice: UploadProps = {
    beforeUpload(file) {
      return false;
    },
    onChange(info) {
      setFile(info.file);
      return;
    },
  };

  const downloadFile = (fileUrl:any, fileName:any) => {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
  
    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    document.body.removeChild(link);
  
    // Log errors
    link.addEventListener('error', (event) => {
      console.error('Error downloading file:', event);
    });
  };

  const dragger_props = {
    name: "file",
    multiple: true,
    action: "/api/upload/gstr2a", // Replace with your API endpoint
    onChange(e:any) {
        if( e.file.status=="done"){
            downloadFile(e.file.response.download_url,"GSTR2A.xlsx")
            return;
        }
    },
  };
  return (
    <>
      <div className="site-card-border-less-wrapper">
        <Card title="Upload GSTR 2A" bordered={false} style={{ width: "99%",padding:"0 0 10% 0" }}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Dragger {...dragger_props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag files to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Dragger>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
}

export default GSTR2A;
