import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

const { Dragger } = Upload;


interface MyComponentProps {
  mainMessage: string;
  quoteMessage: string;
  onChange:any;
  onRemove:any;
  // fileList:any;
}

const DragAndDropUploader: React.FC<MyComponentProps> = ({mainMessage,quoteMessage,onChange,onRemove}) => {

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload : () => false
  };
  return (

    <Dragger {...props} onChange={onChange}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">{mainMessage}</p>
    <p className="ant-upload-hint">
 

{quoteMessage}
    </p>
  </Dragger>
);
}

export default DragAndDropUploader;