/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/MainLayout/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "../components/Sidenav";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../assets/styles/main.css";
import "../assets/styles/responsive.css";
import SidenavCustom from "../components/SidenavCustom";
const { Header: AntHeader, Content, Sider } = Layout;

function MainLayout() {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [collapseSideNav, setCollapseSideNav] = useState(false)
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type:string) => setSidenavType(type);
  const handleSidenavColor = (color:string) => setSidenavColor(color);
  const handleFixedNavbar = (type:boolean) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >

      {/* <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        width={"10%"}
        theme="light"
        className={`ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >

      </Sider> */}
      <SidenavCustom toggleCollapsed={(e:any)=>{setCollapseSideNav(!collapseSideNav)}} collapseSideNav={collapseSideNav}></SidenavCustom>

      <Layout style={{
        transition:'0.3s',
        width:window.innerWidth > 576? collapseSideNav?"95%":"85%":'100%',
        marginLeft:window.innerWidth > 576 ? collapseSideNav?"5%":"15%":'0%'
        
        }} >
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
            />
          </AntHeader>
        )}
        <Content className="content-ant">
          <Outlet/>
        </Content>

      </Layout>
    </Layout>
  );
}



export default MainLayout;
